<template>
<v-row style="margin-top:80px">
   <!-- fiscales -->
    <v-col cols="4">
      <v-hover v-slot:default="{ hover }" open-delay="200">
        <v-card id="fiscales"  class="mx-auto"     :elevation="hover ? 16 : 2">
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <v-card elevation="0" style="height:125px" >
                  <v-card-title class="title font-weight-regular justify-space-between;" :style="colorHeader1">
                  <v-img style="height:40px!important;width:50px!important;transition: width 1s;margin-top:-10px" contain :src="fiscalesIcono" aspect-ratio="1"></v-img>  
                  <span :class="colorTitulo1" style="font-family:'Arial',Times,serif;font-size:19px;font-weight:bold;
                                                    width: 100%;text-align: center;">Datos fiscales &gt;</span>
                </v-card-title>
                </v-card>
              </v-col>

              <v-col cols="12" style="margin-top:-47px">
                <v-card elevation="1" style="height:130px" color="white">
                  <v-card-title class="title font-weight-regular justify-space-between;">
                  <span  style="font-family:'Arial',Times,serif;font-size:18px;font-weight:bold;margin-top:0px;
                                                    width: 100%;text-align: center;">¿Tienes RFC?</span>
                  <table style="margin-top:10px;text-align: center;font-family:'Arial',Times,serif;font-size:14px;font-weight:bold;width: 100%;">
                      <tr>
                        <td><input style="cursor:pointer" type="radio" id="uno" value="si" v-model="tieneRFC" @click="validaForma1('si')"></td>
                        <td><input style="cursor:pointer" type="radio" id="dos" value="no" v-model="tieneRFC" @click="validaForma1('no')"></td>
                      </tr>
                       <tr>
                          <td><label  for="uno" style="margin-top: -2px;position: absolute;margin-left: -4px;">Si</label></td>
                          <td><label  for="dos" style="margin-top: -2px;position: absolute;margin-left: -4px;">No</label></td>
                      </tr>
                    </table>
                 

                </v-card-title>
                </v-card>
              </v-col>

              <v-col cols="12" v-if="tieneRFC=='si'">
                <v-card elevation="0" style="height:95px" color="white">
                  <v-card-title class="title font-weight-regular justify-space-between;">
                  <span  style="font-family:'Arial',Times,serif;font-size:18px;font-weight:bold;margin-top:-20px;
                                                    width: 100%;text-align: center;">Coloca RFC</span>
                  <div  style="margin-top:-10px;text-align: center;width:100%">
                            <v-text-field  style="margin-top:8px;border-radius: 8px;" 
                            label="RFC" 
                            v-model="rfc" solo
                            @keyup="setRFC"
                            maxlength="13"
                            dense :rules="rulesRFC"  hide-details="auto"
                            :background-color="fondoRFC"
                          ></v-text-field>
                </div>
                </v-card-title>
                </v-card>
              </v-col>

              <v-col cols="12" v-if="tieneRFC=='si'" style="margin-top:-10px">
                <v-card elevation="1" style="height:80px" color="white">
                  <v-card-title class="title font-weight-regular justify-space-between;">
                  <span  style="font-family:'Arial',Times,serif;font-size:18px;font-weight:bold;margin-top:-20px;
                                                    width: 100%;text-align: center;">¿Tienes FIEL?</span>

                  <table style="margin-top:-8px;text-align: center;font-family:'Arial',Times,serif;font-size:14px;font-weight:bold;width: 100%;">
                    <tr>
                      <td>
                        <input style="cursor:pointer" type="radio" id="tres" value="si" @click="validaFIEL('si')" v-model="tieneFIEL">
                        </td>
                        <td>
                            <input style="cursor:pointer" type="radio" id="cuatro" value="no" @click="validaFIEL('no')" v-model="tieneFIEL">
                        </td>
                    </tr>
                      <tr>
                      <td>
                        <label for="tres" style="margin-top: -2px;position: absolute;margin-left: -4px;">Si</label>
                        </td><td>
                            <label for="cuatro" style="margin-top: -2px;position: absolute;margin-left: -4px;">No</label>
                        </td>
                    </tr>
                  </table>                                  
                  <!-- <div  style="margin-top:-10px;text-align: center;width:100%">
                    <v-radio-group v-model="tieneFIEL" row style="display: inline-block;">
                    <v-radio label="Si" value="si" @change="validaFIEL"></v-radio>
                    <v-radio label="No" value="no" @change="validaFIEL"></v-radio>
                </v-radio-group>
                </div> -->
                </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-hover>
    </v-col>
    <!-- Propiedades -->
    <v-col cols="4">
      <v-hover v-slot:default="{ hover }" open-delay="200">
        <v-card id="cPropiedades" class="mx-auto"    :elevation="hover ? 16 : 2">
          <v-container>
            <v-row dense>
              <v-col cols="12">
                <v-card elevation="0" style="height:125px" color="white">
                  <v-card-title class="title font-weight-regular justify-space-between;" :style="colorHeader2">
                  <v-img style="height:40px!important;width:50px!important;transition: width 1s;margin-top:-10px" contain :src="propiedadesIcono" aspect-ratio="1"></v-img>  
                  <span :class="colorTitulo2" style="font-family:'Arial',Times,serif;font-size:19px;font-weight:bold;
                                                    width: 100%;text-align: center;">Paquete de servicios &gt;</span>
                </v-card-title>
                </v-card>
              </v-col>
              <v-col cols="12">
                <v-card elevation="1" style="height:130px;margin-top:-47px" color="white">
                  <v-card-title class="title font-weight-regular justify-space-between;">
                  <span  style="font-family:'Arial',Times,serif;font-size:18px;font-weight:bold;margin-top:0px;
                                                    width: 100%;text-align: center;word-break: break-word">Seleccione su paquete</span>
                  <div  style="margin-top:20px;text-align: center;width:100%">
                  <!-- combo -->
                  <v-select
                      :items="propiedadesLista"
                      label="Paquete"
                      solo
                      menu-props="auto"
                      v-model="$store.state.cliente.propiedades"
                      @change="propiedadSeleccionada"
                      style="font-size:14px"
                    ></v-select>
                </div>
                </v-card-title>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
     </v-hover>
    </v-col>
    <!-- Cotizacion -->
     <v-col cols="4">
      <v-container>
        <v-row dense>
          <v-col cols="12">
             <v-hover v-slot:default="{ hover }" open-delay="200">
              <v-card id="cCotizacion"  class="mx-auto"    style="margin-top:-15px" :elevation="hover ? 16 : 2">
                    <v-container>
                      <v-row dense>
                          <v-col cols="12">
                            <v-card elevation="0" style="height:145px" color="white">
                              <v-card-title class="title font-weight-regular justify-space-between;" :style="colorHeader3">
                              <v-img style="height:40px!important;width:50px!important;transition: width 1s;margin-top:-10px" contain :src="cotizacionIcono" aspect-ratio="1"></v-img>  
                              <span :class="colorTitulo3" style="font-family:'Arial',Times,serif;font-size:20px;font-weight:bold;
                                                                width: 100%;text-align: center;">Cotización</span>
                            </v-card-title>
                            </v-card>
                          </v-col>

                          <v-col cols="12" style="margin-top:-64px">
                            <v-card elevation="1" style="height:131px" color="white">
                              <v-card-title class="title font-weight-regular justify-space-between;">
                              <span v-if="showDesde"  style="font-family:'Arial',Times,serif;font-size:35px;font-weight:bold;margin-top:30px;
                                                                width: 100%;text-align: center;">${{getCalculoDesde}}</span>
                              <div  style="margin-top:10px;text-align: center;width:100%">
                              
                            </div>
                            </v-card-title>
                            </v-card>
                          </v-col>

                        <v-col v-if="showDesde" style="margin-top:30px;height:184px">
                          <div class="text-center">
                              <v-btn rounded color="error" style="width:130px;font-size:18px;text-transform: lowercase;display: inline-block;" 
                                     dark @click="pagarEvento" >Siguiente</v-btn>
                          </div>

                          <!-- <div class="text-center">
                              <v-btn rounded color="grey" style="width:130px;font-size:18px;margin-top:15px;text-transform: lowercase;display: inline-block;" dark @click="pagarMasTardeEvento">Más tarde</v-btn>
                          </div> -->
                        </v-col>
                      </v-row>


                    </v-container>
              </v-card>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </v-col>

</v-row>


</template>

<script>
import { VueMaskDirective   } from 'v-mask'

  export default {
    directives: {
      'mask': VueMaskDirective
    },
    components: {
     
    },
    data: () => ({
      showDesde:false,
      cotizacionIcono:'https://utaxon.com/img/COTIZACION-GRIS.png',
      tieneRFC:null,
      tieneFIEL:null,
      rfc:'',
      rulesRFC: [
        value => !!value || 'Requerido.',
        value => (value && value.length >= 12 && value.length < 14) || 'El RFC debe ser de 12 o 13 dígitos',
      ],
      fondoRFC:"white",
      fiscalesIcono:'https://utaxon.com/img/DATOS-FISCALES.png',
      propiedadesIcono:'https://utaxon.com/img/PROPIEDADES-GRIS.png',
      //propiedadesLista: ['01', '02', '03', '04','05','06','07','08','09','10','11', '12', '13', '14','15','16','17','18','19','20','21', '22', '23', '24','25','26','27','28','29','30','31', '32', '33', '34','35','36','37','38','39','40','41', '42', '43', '44','45','46','47','48','49','50','51', '52', '53', '54','55','56','57','58','59','60','61', '62', '63', '64','65','66','67','68','69','70','71', '72', '73', '74','75','76','77','78','79','80','81', '82', '83', '84','85','86','87','88','89','90','91', '92', '93', '94','95','96','97','98','99'],
      propiedadesLista:['PAQUETE BÁSICO','PAQUETE AVANZADO','PAQUETE PREMIUM'],
      colorTitulo1:'tituloAzul',
      colorTitulo2:'tituloGris',
      colorTitulo3:'tituloGris',
      colorHeader1:'background-color:#227aff69',
      colorHeader2:'background-color:#fff',
      colorHeader3:'background-color:#fff',
      dialogPropuesta:true,
      e6: '1',
      dialog: false,
      garantia:'https://previews.123rf.com/images/nerthuz/nerthuz1304/nerthuz130400003/18840808-satisfaction-guaranteed-label-with-gold-badge-sign.jpg',
      items: [
        {
          color: '#FB8C00',
          src: 'https://www.youtravel.com.au/wp-content/uploads/2017/05/airbnb.jpg',
          title: 'Apreciable, José Manuel!',
          artist: 'Agradecemos la oportunidad de presentar nuestra propuesta de servicios como Despacho Contable El paquete de servicio es de pago mensual y sin plazos forzosos.',
        }
      ],
      itemsPropuesta: [
        {
          color: '#fff',
          src: 'https://previews.123rf.com/images/nerthuz/nerthuz1304/nerthuz130400003/18840808-satisfaction-guaranteed-label-with-gold-badge-sign.jpg',
          title: 'Apreciable, José Manuel!',
          artist: 'Agradecemos la oportunidad de presentar nuestra propuesta de servicios como Despacho Contable El paquete de servicio es de pago mensual y sin plazos forzosos.',
        }
      ],
       propuestaItems: [
        { header: '.' },
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg', title: 'Emisión de facturas', subtitle: "<ul style='word-break:break-word;font-size:10px'><li >EMISIÓN DE 50 FACTURAS A TUS CLIENTES</li><li>EMISION DE FACTURAS AL PUBLICO EN GRAL</li></ul>" },
        { divider: true, inset: true },
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg', title: 'Declaraciones', subtitle: "<ul style='word-break:break-word;font-size:10px'><li>CALCULO DE IMPUESTOS PARA DECLARACIONES<br></li>PROVISIONALES</li><li>PRESENTACION DE DECLARACIONES<br></li>INFORMATIVAS</li><li>PRESENTACION DE DECLARACION ANUAL</li></ul>" },
        { divider: true, inset: true },
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg', title: 'Asesorias y seguimiento', subtitle: "<lu style='word-break:break-word;font-size:10px'><li>ASESORIAS FISCALES</li><li>TIPS PARA MEJOR DEDUCCIONES DE IMPUESTOS</li><li>ASESORIAS PERSONALIZADAS (TELEFONO, WHATSAPP, SKYPE, CORREO)</li><li>PLATFORMA WEB (CALCULOS DE IMPUESTOS),</li><li>SEGUIMIENTO DECLARACIONES</li></lu>" },
      ],
    }),
     mounted() {
        this.$nextTick(function() {
        window.addEventListener('resize', this.getWindowWidth);
        window.addEventListener('resize', this.getWindowHeight);
        //Init
        //this.getWindowWidth()
        this.getWindowHeight() 
      })

    },
    computed: {
       getCalculoDesde(){
         return this.$store.getters.getDesde;
      },
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.getWindowWidth);
      window.removeEventListener('resize', this.getWindowHeight);
    },
   methods:{
     pagarEvento(){
      //this.$store.state.loading=false;
      this.$store.state.dialogPropuesta=true;
      
      this.$store.state.cliente.pagar= true;
      this.$store.state.cliente.mastarde= false;
     },
     pagarMasTardeEvento(){
        //this.$store.state.loading=false;
        this.$store.state.dialogPropuestaMasTarde=true

        this.$store.state.cliente.pagar= false;
        this.$store.state.cliente.mastarde= true;
     },
     change1(){
       validaForma1('si');
     },
     change2(){
       validaForma1('no');
     },
     validaForma1(valor){
        if(valor=="no"){
          this.$store.state.loading=false;
          this.$store.state.cliente.tieneRFC=false;
          this.colorTitulo2="tituloAzul";
          this.propiedadesIcono='https://utaxon.com/img/PROPIEDADES.png';
          this.colorHeader2 = 'background-color:#227aff69';
        }else{
           this.$store.state.loading=true; 
           this.$store.state.cliente.tieneRFC=true;
           this.showDesde=false;
           this.cotizacionIcono='https://utaxon.com/img/COTIZACION-GRIS.png';
           this.colorTitulo2="tituloGris";
           this.propiedadesIcono='https://utaxon.com/img/PROPIEDADES-GRIS.png';
           this.colorTitulo3="tituloGris";
           this.$store.state.cliente.propiedades=0;
           this.colorHeader2 = 'background-color:#fff';
           this.colorHeader3 = 'background-color:#fff';
        }
     },
      propiedadSeleccionada(){
        this.$store.state.loading=false;
        this.colorTitulo3="tituloAzul";
        this.showDesde=true;
        this.cotizacionIcono='https://utaxon.com/img/COTIZACION.png';
        this.colorHeader3 = 'background-color:#227aff69';
      },
     validaFIEL(valor){
       if(valor=='si'){
         this.$store.state.cliente.tieneFIEL=true;
       }else{
         this.$store.state.cliente.tieneFIEL=false;
       }
          this.$store.state.loading=false; 
          this.colorTitulo2="tituloAzul";
          this.propiedadesIcono='https://utaxon.com/img/PROPIEDADES.png';
          this.colorHeader2 = 'background-color:#227aff69';
     },
     getWindowHeight(event) {
       var altura1=80;
       if(document.documentElement.clientHeight <500){
         console.log("menor a 500");
           document.getElementById("fiscales").style.height=(document.documentElement.clientHeight+ altura1)+"px";
           document.getElementById("cPropiedades").style.height=(document.documentElement.clientHeight+ altura1)+"px";
           document.getElementById("cCotizacion").style.height=(document.documentElement.clientHeight+altura1)+"px";
        }else{
          
           document.getElementById("fiscales").style.height=(document.documentElement.clientHeight-400)+"px";
           document.getElementById("cPropiedades").style.height=(document.documentElement.clientHeight-400)+"px";
           document.getElementById("cCotizacion").style.height=(document.documentElement.clientHeight-400)+"px";
        } 
        },
     setRFC(event){
            console.log(event.key);
            var regex = new RegExp("^[a-zA-Z0-9]+$");
            var key = event.key;//String.fromCharCode(!event.key ? event.which : event.charCode);
            if (!regex.test(key)) {
              this.rfc=this.rfc.replace(event.key,'')
              event.preventDefault();
              return false;
            }

       
            this.rfc=this.rfc.toUpperCase();
            this.$store.state.cliente.rfc= this.rfc;
            if(this.rfc.length>=15){
              this.fondoRFC="#f0f8ff";
            }
        },
   }
  }
</script>
<style scoped>

input[type='radio']:after {
        width: 22px;
        height: 22px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: white;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 9px solid #d1d3d1;
        z-index: 1;
    }

    input[type='radio']:checked:after {
        width: 22px;
        height: 22px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: white;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 9px solid #013872;
        z-index: 1;
    }

.tituloGris{
  color:#c7c3c3;
}

.tituloAzul{
  color:#013872;
}
</style>