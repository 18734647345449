<template>
     <v-card class="mx-auto" style="height:485px!important">
    <v-container>
      <v-row >
        <v-col cols="12">
         
              
          <v-card style="height:55px" color="green" dark >
            <v-card-title style="font-size:25px!important;word-break:break-word;line-height: 1.4rem;margin-top:-10px"  class="">
              <span style="margin-top:5px">Procesando.....</span></v-card-title>
              <div class="text-center">
                <v-progress-circular style="margin-top:50px"
                indeterminate
                color="success"
                width="10"
                size="150"
              ></v-progress-circular>
              </div>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  
  </v-card>
</template>

<script>

  export default {
    components: {
     
    },
    data: () => ({
     
    }),
  }
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>