<template>
     <v-card
    class="mx-auto">
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <v-card style="height:55px" color="green" dark >
            <v-card-title style="font-size:18px!important;word-break:break-word;line-height: 1.4rem;margin-top:-10px"  class=""><span style="margin-top:-10px">La compra ha sido realizada de forma correcta.</span></v-card-title>
            <!-- <v-card-subtitle style="word-break:break-word;line-height: 1.4rem;">Su transacción ha sido asegurada y sus datos protegidos</v-card-subtitle> -->
          </v-card>
        </v-col>
        <v-col
          v-for="(item, i) in itemsPropuesta"
          :key="i"
          cols="12">
          <v-card 
            :color="item.color"
            dark>
            <div class="d-flex flex-no-wrap justify-space-between" style="height:100px">
              <v-col cols=8>
               <div style="margin-top:-10px">
                <span class="headline" style="color:black;display:inline-block;margin-left: 1px;font-size:18px!important">Apreciable, cliente</span>
                <v-card-subtitle style="word-break: break-word;line-height: 0.9rem;margin-left: -14px;font-size: 12px;margin-top:-15px;width: 100%;" class="black--text" v-text="item.artist"></v-card-subtitle>
              </div>
              </v-col>
               <v-col cols=4>
                 <v-avatar style="top:-13px;right:-19px;position: absolute;" class="ma-3" size="100" tile>
                <v-img :src="item.src"></v-img>
              </v-avatar>
              </v-col>
            </div>

            <v-card-text style="background-color: #a9a9a93b;padding:7px;">
              <v-card style=";" class="mx-auto" height="250" color="white" tile>
              <v-list two-line style="background-color:white;" dense>
                <template>
                  <!-- <v-list-item style="color:grey;margin-top:-34px;margin-left:-8px;" :key="item.index">
                    <v-list-item-content>
                      <v-list-item-title style="color:grey;word-break:break-word;line-height: 0.9rem;margin-top:0px" v-html="item.title"></v-list-item-title>
                      <v-list-item-subtitle style="color:black;margin-left: -9px;font-size:11px" v-html="item.subtitle"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item> -->
                   <v-list-item style="color:grey;margin-top:-14px;margin-left:-8px;">
                    <v-list-item-content>
                      <v-list-item-title style="color:grey;word-break:break-word;line-height: 0.9rem;margin-top:0px">Datos de contacto</v-list-item-title>
                      <v-list-item-subtitle style="color:black;margin-left: -9px;font-size:11px" v-html="subtitulo"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item style="color:grey;margin-top:4px;margin-left:-8px;">
                    <v-list-item-content>
                      <v-list-item-title style="color:grey;word-break:break-word;line-height: 0.9rem;margin-top:0px" >Dudas</v-list-item-title>
                      <v-list-item-subtitle style="color:black;margin-left: -9px;font-size:11px" v-html="subtitle2"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item style="color:grey;margin-top:-4px;margin-left:-8px;">
                    <v-list-item-content>
                      <v-list-item-title style="color:grey;word-break:break-word;line-height: 0.9rem;margin-top:0px">Agente asignado</v-list-item-title>
                      <v-list-item-subtitle style="color:black;margin-left: -9px;font-size:11px" v-html="subtitle3"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                </template>
              </v-list>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  
  </v-card>
</template>

<script>

  export default {
    components: {
     
    },
    data: () => ({
      subtitle1:'',
      subtitle2:'<ul><li>El número 55 6366 5042 para dudas</li><li>Contamos con un call center que estara<br>gustoso de atenderle</li></ul>',
      subtitle3:'<lu><li>El CP Ricardo Morales García estará pendiente de ud.</li></lu>',
      folio:'',
      dialogPropuesta:true,
      e6: '1',
      dialog: false,
      garantia:'http://www.abedulart.com/media/queldorei/shopper/block/compra-segura.png',
      items: [
        {
          color: '#FB8C00',
          src: 'https://www.youtravel.com.au/wp-content/uploads/2017/05/airbnb.jpg',
          title: 'Apreciable, José Manuel!',
          artist: 'Agradecemos la oportunidad de presentar nuestra propuesta de servicios como Despacho Contable El paquete de servicio es de pago mensual y sin plazos forzosos.',
        }
      ],
      itemsPropuesta: [
        {
          color: '#fff',
          src: 'http://www.abedulart.com/media/queldorei/shopper/block/compra-segura.png',
          title: 'Apreciable, José Manuel!',
          artist: 'A partir de este momento esta listo para gozar de los servicios del Despacho Contable',
        }
      ],
       propuestaItems: [
        { header: '.' },
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg', title: 'Datos de contacto', subtitle: "<ul><li>Su código de socio es el F00001</li><li>Se ha enviado a su correo el contrato</li></ul>" },
        { divider: true, inset: true },
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg', title: 'Dudas', subtitle: "<ul><li>El número 55 6366 5042 para dudas</li><li>Contamos con un call center que estara<br>gustoso de atenderle</li></ul>" },
        { divider: true, inset: true },
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg', title: 'Agente asignado', subtitle: "<lu><li>El CP Ricardo Morales García estará pendiente de ud.</li></lu>" },
      ],
    }),
     computed:{
         subtitulo () {          
          return "<ul><li>Su código de socio es el "+ this.$store.getters.getCliente.folio  +"</li><li>Se ha enviado a su correo el contrato</li></ul>";
      },
    },
    mounted() {
      console.log("on mounted");
      console.log(this.$store.getters.getCliente.folio);
      this.subtitle1="<ul><li>Su código de socio es el "+ this.$store.getters.getCliente.folio  +"</li><li>Se ha enviado a su correo el contrato</li></ul>";
    },
    created(){
      console.log("on created");
      console.log(this.$store.getters.getCliente.folio);
      this.subtitle1="<ul><li>Su código de socio es el "+ this.$store.getters.getCliente.folio  +"</li><li>Se ha enviado a su correo el contrato</li></ul>";
      //"+ this.$store.state.cliente.folio  +"
     // this.subtitle1="<ul><li>Su código de socio es el "+ this.$store.getters.getCliente.folio  +"</li><li>Se ha enviado a su correo el contrato</li></ul>";
    }
  }
</script>
