<template>
<!-- Pantalla incial del proceso despues de elejir el paquete-->
     <v-card class="mx-auto" style="width: 99%">
    <v-container style="padding-left:2px;padding-right:2px">
      <v-row dense>
        <!-- <v-col cols="12">
          <v-card style="height:33px" color="green" dark >
            <v-card-title style="font-size:18px!important;word-break:break-word;line-height:1.1rem;margin-top:-14px" class="">
              <span style="margin-top:-7px">!Felicidades!, ha concluido el proceso</span></v-card-title>
          </v-card>
        </v-col> -->
        
        <v-col v-for="(item, i) in itemsPropuesta" :key="i" cols="12">
          <v-card  :color="item.color" dark>
            <div class="d-flex flex-no-wrap justify-space-between" style="height:71px">
              <v-col cols=9>
               <div style="margin-top:-20px;width:100%">
                <span class="headline" style="color:black;display:inline-block;font-weight:bold;margin-left:-5px;font-size:17px!important">Apreciable, cliente</span>
                <v-card-subtitle style="word-break:break-word;line-height: 0.9rem;margin-left:-20px;width: 111%;font-size: 12px;margin-top: -22px;" 
                                 class="black--text" v-text="item.artist"></v-card-subtitle>
              </div>
              </v-col>
              <v-col cols="3">
                <v-avatar style="top:-30px;right:-7px;position: absolute;z-index:3000" class="ma-3" size="110" >
                <v-img :src="item.src" ></v-img>
              </v-avatar>
              </v-col>
             
            </div>

            <v-card-text style="background-color: #a9a9a93b;padding:5px;margin-top:-6px">
              <v-card style="background-color: white;" color="white" tile>
              <v-list two-line style="background-color:white;" dense>
                <template v-for="(item) in getPropuestas" >
                  <v-list-item style="padding-left:3px;padding-right:1px;margin-top:-35px;" :key="item.title">
                    <v-list-item-content>
                      <v-list-item-title style="color:green;font-weight:bold;word-break:break-word;line-height: 0.7rem;" v-html="item.title"></v-list-item-title>
                      <v-list-item-subtitle style="color:black;margin-left:-15px;font-size:5px!important" v-html="item.subtitle"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>

              </v-card>
              
            </v-card-text>

          </v-card>
        </v-col>
      </v-row>


    </v-container>
  
  </v-card>
</template>

<script>

  export default {
    components: {
     
    },
    data: () => ({
      dialogPropuesta:true,
      e6: '1',
      dialog: false,
      garantia:'https://previews.123rf.com/images/nerthuz/nerthuz1304/nerthuz130400003/18840808-satisfaction-guaranteed-label-with-gold-badge-sign.jpg',
      items: [
        {
          color: '#FB8C00',
          src: 'https://www.youtravel.com.au/wp-content/uploads/2017/05/airbnb.jpg',
          title: 'Apreciable, José Manuel!',
          artist: 'Agradecemos la oportunidad de presentar nuestra propuesta de servicios como Despacho Contable El paquete de servicio es de pago mensual y sin plazos forzosos.',
        }
      ],
      itemsPropuesta: [
        {
          color: '#fff',
          src: 'https://previews.123rf.com/images/nerthuz/nerthuz1304/nerthuz130400003/18840808-satisfaction-guaranteed-label-with-gold-badge-sign.jpg',
          title: 'Apreciable, José Manuel!',
          artist: 'El paquete de servicio es de pago mensual y sin plazos forzosos',
        }
      ],
       propuestaItems: [
        { header: '.' },
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg', title: 'Emisión de facturas', subtitle: "<ul style='word-break:break-word;font-size:10px'><li>EMISIÓN DE RECIBOS DE RETENCIONES</li><li>EMISIÓN DE FACTURAS A TUS CLIENTES</li></ul>" },
        { divider: true, inset: true },
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg', title: 'Declaraciones', subtitle: "<ul style='word-break:break-word;font-size:10px'><li>CALCULO DE IMPUESTOS PARA DECLARACIONES<br></li>PROVISIONALES</li><li>PRESENTACION DE DECLARACIONES<br></li>INFORMATIVAS MENSUAL</li><li>PRESENTACION DE DECLARACION ANUAL</li></ul>" },
        { divider: true, inset: true },
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg', title: 'Asesorias y seguimiento', subtitle: "<lu style='word-break:break-word;font-size:10px'><li>ASESORIAS FISCALES</li><li>TIPS PARA MEJOR DEDUCCIONES DE IMPUESTOS</li><li>ASESORIAS PERSONALIZADAS (TELEFONO, </li><li>WHATSAPP, SKYPE, CORREO)</li><li>PLATFORMA WEB (CALCULOS DE IMPUESTOS),</li><li>SEGUIMIENTO DECLARACIONES</li></lu>" },
      ],
    }),
    computed:{
      getPropuestas(){

        if(this.$store.state.cliente.propiedades=='PAQUETE BÁSICO'){
        // state.cliente.propiedades=1;
          return [
                { header: '.' },
                { avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg', title: 'Emisión de facturas', subtitle: "<ul style='word-break:break-word;font-size:10px;margin-top:-2px'><li style='margin-top:2px'>EMISIÓN DE RECIBOS DE RETENCIONES PARA <br><div style='margin-top:-5px'>LA PLATAFORMA</div></li><li style='margin-top:-5px'>EMISIÓN DE HASTA 20 FACTURAS A TUS CLIENTES</li></ul><div style:'height:0px'>xx</div>" },
                { divider: true, inset: true },
                { avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg', title: 'Declaraciones', subtitle: "<ul style='word-break:break-word;font-size:10px;margin-top:-2px'><li style='margin-top:2px'>CALCULO DE IMPUESTOS FEDERALES Y LOCALES</li><li style='margin-top:-5px'>PRESENTACION DE DECLARACIONES MENSUALES</li><li style='margin-top:-5px'>DECLARACIONES INFORMATIVAS</li></ul><div style:'height:0px'>xx</div>" },
                { divider: true, inset: true },
                { avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg', title: 'Asesorias y seguimiento', subtitle: "<ul style='word-break:break-word;font-size:10px;margin-top:-2px'><li style='margin-top:2px'>ATENCIÓN DE REQUERIMIENTOS SOLICITADOS POR EL SAT</li><li style='margin-top:-5px'>ASESORIAS FISCALES (CALL CENTER, WHATSAPP, CORREO)</li><li style='margin-top:-5px'>ASESORIA PERSONALIZADA PARA DEDUCCIONES</li><li style='margin-top:-5px'>SITIO WEB DE CLIENTES</li><li style='margin-top:-5px'>DASHBOARD CUMPLIMIENTO FISCAL</li><li style='margin-top:-5px'>DASHBOARD CALCULO DE IMPUESTOS</li><li style='margin-top:-5px'>DASHBOARD REPORTE INGRESOS DE PERIODO <br><div style='margin-top:-5px'>ACTUAL O ANTERIORES</div></li><li style='margin-top:-5px'>DASHBOARD REPORTE DE DEDUCCIONES DEL PERIODO <br><div style='margin-top:-4px'>ACTUAL O ANTERIORES</div></li><li style='margin-top:-5px'>TIPS PERSONALIZADOS PARA DEDUCCIONES DE IMPUESTOS</li></ul><div style:'height:0px'>xx</div>" },
               ];
        }
        else if(this.$store.state.cliente.propiedades=='PAQUETE AVANZADO'){
          //state.cliente.propiedades=2;
          return [
                { header: '.' },
                { avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg', title: 'Emisión de facturas', subtitle: "<ul style='word-break:break-word;font-size:10px;margin-top:-2px'><li style='margin-top:2px'>EMISIÓN DE RECIBOS DE RETENCIONES PARA <br><div style='margin-top:-5px'>LA PLATAFORMA</div></li><li style='margin-top:-5px'>EMISIÓN DE HASTA 40 FACTURAS A TUS CLIENTES</li></ul><div style:'height:0px'>xx</div>" },
                { divider: true, inset: true },
                { avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg', title: 'Declaraciones', subtitle: "<ul style='word-break:break-word;font-size:10px;margin-top:-2px'><li style='margin-top:2px'>CALCULO DE IMPUESTOS FEDERALES Y LOCALES</li><li style='margin-top:-5px'>PRESENTACION DE DECLARACIONES MENSUALES</li><li style='margin-top:-5px'>DECLARACIONES INFORMATIVAS</li><li style='margin-top:-5px'>DECLARACION ANUAL</li><li style='margin-top:-5px'>PRESENTACION DE DECLARACION ANUAL</li><li style='margin-top:-5px'>SOLICITUD DE DEVOLUCION DE IMPUESTOS</li></ul><div style:'height:0px'>xx</div>" },
                { divider: true, inset: true },
                { avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg', title: 'Asesorias y seguimiento', subtitle: "<ul style='word-break:break-word;font-size:10px;margin-top:-2px'><li style='margin-top:2px'>ATENCIÓN DE REQUERIMIENTOS SOLICITADOS POR EL SAT</li><li style='margin-top:-5px'>ASESORIAS FISCALES (CALL CENTER, WHATSAPP, CORREO)</li><li style='margin-top:-5px'>ASESORIA PERSONALIZADA PARA DEDUCCIONES</li><li style='margin-top:-5px'>SITIO WEB DE CLIENTES</li><li style='margin-top:-5px'>DASHBOARD CUMPLIMIENTO FISCAL</li><li style='margin-top:-5px'>DASHBOARD CALCULO DE IMPUESTOS</li><li style='margin-top:-5px'>DASHBOARD REPORTE INGRESOS DE PERIODO <br><div style='margin-top:-5px'>ACTUAL O ANTERIORES</div></li><li style='margin-top:-5px'>DASHBOARD REPORTE DE DEDUCCIONES DEL PERIODO <br><div style='margin-top:-4px'>ACTUAL O ANTERIORES</div></li><li style='margin-top:-5px'>TIPS PERSONALIZADOS PARA DEDUCCIONES DE IMPUESTOS</li></ul>" },
               ];
        }
        else if(this.$store.state.cliente.propiedades=='PAQUETE PREMIUM'){
          //state.cliente.propiedades=3;
          return [
                { header: '.' },
                { avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg', title: 'Emisión de facturas', subtitle: "<ul style='word-break:break-word;font-size:10px;margin-top:-2px'><li style='margin-top:2px'>EMISIÓN DE RECIBOS DE RETENCIONES PARA <br><div style='margin-top:-5px'>LA PLATAFORMA</div></li><li style='margin-top:-5px'>EMISIÓN DE HASTA 120 FACTURAS A TUS CLIENTES</li></ul>" },
                { divider: true, inset: true },
                { avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg', title: 'Declaraciones', subtitle: "<ul style='word-break:break-word;font-size:10px;margin-top:-2px'><li style='margin-top:2px'>CALCULO DE IMPUESTOS FEDERALES Y LOCALES</li><li style='margin-top:-5px'>PRESENTACION DE DECLARACIONES MENSUALES</li><li style='margin-top:-5px'>DECLARACIONES INFORMATIVAS</li><li style='margin-top:-5px'>DECLARACION ANUAL</li><li style='margin-top:-5px'>PRESENTACION DE DECLARACION ANUAL</li><li style='margin-top:-5px'>SOLICITUD DE DEVOLUCION DE IMPUESTOS</li><li style='margin-top:-5px'>CONTABILIDAD ELECTRÓNICA</li><li style='margin-top:-5px'>CONCILIACIONES BANCARIAS DE ESTADOS DE CUENTA</li><li style='margin-top:-5px'>ENVIO DE LA CONTABILIDAD ELECTRÓNICA</li></ul>" },
                { divider: true, inset: true },
                { avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg', title: 'Asesorias y seguimiento', subtitle: "<ul style='word-break:break-word;font-size:10px;margin-top:-2px'><li style='margin-top:2px'>ATENCIÓN DE REQUERIMIENTOS SOLICITADOS POR EL SAT</li><li style='margin-top:-5px'>ASESORIAS FISCALES (CALL CENTER, WHATSAPP, CORREO)</li><li style='margin-top:-5px'>ASESORIA PERSONALIZADA PARA DEDUCCIONES</li><li style='margin-top:-5px'>SITIO WEB DE CLIENTES</li><li style='margin-top:-5px'>DASHBOARD CUMPLIMIENTO FISCAL</li><li style='margin-top:-5px'>DASHBOARD CALCULO DE IMPUESTOS</li><li style='margin-top:-5px'>DASHBOARD REPORTE INGRESOS DE PERIODO <br><div style='margin-top:-5px'>ACTUAL O ANTERIORES</div></li><li style='margin-top:-5px'>DASHBOARD REPORTE DE DEDUCCIONES DEL PERIODO <br><div style='margin-top:-4px'>ACTUAL O ANTERIORES</div></li><li style='margin-top:-5px'>TIPS PERSONALIZADOS PARA DEDUCCIONES DE IMPUESTOS</li></ul>" },
               ];
        }
        else{
          //state.cliente.propiedades=0;
           return [
                { header: '.' },
                { avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg', title: 'Emisión de facturas', subtitle: "<ul style='word-break:break-word;font-size:9px'><li>EMISIÓN DE RECIBOS DE RETENCIONES</li><li>EMISIÓN DE FACTURAS A TUS CLIENTES</li></ul>" },
                { divider: true, inset: true },
                { avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg', title: 'Declaraciones', subtitle: "<ul style='word-break:break-word;font-size:9px'><li>CALCULO DE IMPUESTOS PARA DECLARACIONES<br></li>PROVISIONALES</li><li>PRESENTACION DE DECLARACIONES<br></li>INFORMATIVAS MENSUAL</li><li>PRESENTACION DE DECLARACION ANUAL</li></ul>" },
                { divider: true, inset: true },
                { avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg', title: 'Asesorias y seguimiento', subtitle: "<lu style='word-break:break-word;font-size:9px'><li>ASESORIAS FISCALES</li><li>TIPS PARA MEJOR DEDUCCIONES DE IMPUESTOS</li><li>ASESORIAS PERSONALIZADAS (TELEFONO, </li><li>WHATSAPP, SKYPE, CORREO)</li><li>PLATFORMA WEB (CALCULOS DE IMPUESTOS),</li><li>SEGUIMIENTO DECLARACIONES</li></lu>" },
               ];
        } 
        
      }
    }

  }
</script>
