<template>
     <v-card
    class="mx-auto">
    <v-container>
      <v-row dense>
        <v-col cols="12">
          <v-card style="height:60px" color="green" dark >
            <v-card-title style="font-size:18px!important;word-break:break-word;line-height: 1.4rem;margin-top:-10px" class="">
              <span style="margin-top:-6px">No se preocupe si no puede pagar en este momento.</span></v-card-title>
          </v-card>
        </v-col>
        <v-col
          v-for="(item, i) in itemsPropuesta"
          :key="i"
          cols="12">
          <v-card 
            :color="item.color"
            dark>
            <div class="d-flex flex-no-wrap justify-space-between" style="height:60px">
              <v-col cols=9>
               <div style="margin-top:-13px">
                <span class="headline" style="color:black;display:inline-block;font-weight:bold;font-size:16px!important">Apreciable, cliente</span>
                <v-card-subtitle style="word-break:break-word;line-height: 0.9rem;margin-left:-15px;width: 116%;font-size: 12px;
                margin-top: -22px;" class="black--text" v-text="item.artist"></v-card-subtitle>
              </div>
              </v-col>
              <v-col cols="3">
                <v-avatar style="top:-17px;right:-10px;position: absolute;" class="ma-3" size="65" tile>
                <v-img :src="item.src" ></v-img>
              </v-avatar>
              </v-col>
             
            </div>

            <v-card-text style="background-color: #a9a9a93b;padding:5px">

              <v-card style="background-color: white;" color="white" tile>
               
              <v-list two-line style="background-color:white;" dense>
                <template v-for="(item) in propuestaItems" >
                  <!-- <v-subheader style="height:0px"  :key="item.header"></v-subheader> -->
                  <!-- <v-divider v-else-if="item.divider" :key="index" :inset="item.inset"></v-divider> -->
                  <v-list-item style="padding-left:3px;padding-right:0px;margin-top:-38px;" :key="item.title">
                    
                    <v-list-item-content>
                      <v-list-item-title style="color:green;font-weight:bold;word-break:break-word;line-height: 0.9rem;" v-html="item.title"></v-list-item-title>
                      <v-list-item-subtitle style="color:black;margin-left: -9px;font-size:11px" v-html="item.subtitle"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>


              </v-card>
              
              
              
            </v-card-text>


          </v-card>
        </v-col>
      </v-row>


    </v-container>
  
  </v-card>
</template>

<script>

  export default {
    components: {
     
    },
    data: () => ({
      dialogPropuesta:true,
      e6: '1',
      dialog: false,
      garantia:'https://previews.123rf.com/images/nerthuz/nerthuz1304/nerthuz130400003/18840808-satisfaction-guaranteed-label-with-gold-badge-sign.jpg',
      items: [
        {
          color: '#FB8C00',
          src: 'https://www.youtravel.com.au/wp-content/uploads/2017/05/airbnb.jpg',
          title: 'Apreciable, José Manuel!',
          artist: 'Agradecemos la oportunidad de presentar nuestra propuesta de servicios como Despacho Contable El paquete de servicio es de pago mensual y sin plazos forzosos.',
        }
      ],
      itemsPropuesta: [
        {
          color: '#fff',
          src: 'https://previews.123rf.com/images/nerthuz/nerthuz1304/nerthuz130400003/18840808-satisfaction-guaranteed-label-with-gold-badge-sign.jpg',
          title: 'Apreciable, José Manuel!',
          artist: 'Vea nuestra propuesta para que le enviemos su cotización.',
        }
      ],
       propuestaItems: [
        { header: '.' },
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg', title: 'Emisión de facturas', subtitle: "<ul style='word-break:break-word;font-size:10px'><li>EMISIÓN DE RECIBOS DE RETENCIONES</li><li>EMISIÓN DE FACTURAS A TUS CLIENTES</li></ul>" },
        { divider: true, inset: true },
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg', title: 'Declaraciones', subtitle: "<ul style='word-break:break-word;font-size:10px'><li>CALCULO DE IMPUESTOS PARA DECLARACIONES<br></li>PROVISIONALES</li><li>PRESENTACION DE DECLARACIONES<br></li>INFORMATIVAS MENSUAL</li><li>PRESENTACION DE DECLARACION ANUAL</li></ul>" },
        { divider: true, inset: true },
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg', title: 'Asesorias y seguimiento', subtitle: "<lu style='word-break:break-word;font-size:10px'><li>ASESORIAS FISCALES</li><li>TIPS PARA MEJOR DEDUCCIONES DE IMPUESTOS</li><li>ASESORIAS PERSONALIZADAS (TELEFONO, </li><li>WHATSAPP, SKYPE, CORREO)</li><li>PLATFORMA WEB (CALCULOS DE IMPUESTOS),</li><li>SEGUIMIENTO DECLARACIONES</li></lu>" },
      ],
    }),

  }
</script>
