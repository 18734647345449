<template>
 <v-container class="white lighten-5">
   <!-- vista mobile -->
     <v-card v-if="$store.state.mobile" class="mx-auto">
        <v-window v-model="step">
            <v-window-item :value="1" >
               <fiscales></fiscales>
            </v-window-item>
            <v-window-item :value="2">
                <propiedades></propiedades>
            </v-window-item>
            <v-window-item :value="3">
                <cotizacion></cotizacion>
            </v-window-item>
        </v-window>
        <v-divider></v-divider>
        <v-card-actions>
            <v-btn  :disabled="step === 1" text @click="validaBack">Atras</v-btn>
            <v-spacer></v-spacer>
              <v-btn v-if="siguienteOK" color="#6292ba"
                    :disabled="$store.state.loading"
                    depressed
                    @click="validaNext"> {{btnSiguiente}}
            </v-btn>
            
        </v-card-actions>
     </v-card>
  <!-- vista PC -->
      <v-card v-if="$store.state.pc" class="mx-auto" flat>
        <v-window  v-model="step">
            <v-window-item :value="1">
                  <todos></todos>
            </v-window-item>
        </v-window>
     
     </v-card>


    <!--propuesta paga ahora-->
    <!-- <v-row justify="center" > -->
        <v-dialog   transition="dialog-bottom-transition" v-model="$store.state.dialogPropuesta" style="margin-left:2px;margin-right:2px" persistent max-width="600px">
            <v-card >

                <v-window v-model="steppagar">
                    <v-window-item :value="1">
                        <Propuesta></Propuesta>
                    </v-window-item>

                    <v-window-item :value="2">
                        <Propuesta2></Propuesta2>
                    </v-window-item>

                    <v-window-item :value="3">
                        <CreditCardForm></CreditCardForm>
                    </v-window-item>

                    <v-window-item :value="4" v-if="procesando">
                        <Procesando></Procesando>
                    </v-window-item>
                     <v-window-item v-else :value="4" >
                          <Final></Final>
                    </v-window-item>
                </v-window>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn  :disabled="steppagar === 1" v-if ="steppagar > 1 && steppagar < 4" text @click="atrasPagar">
                    <v-icon large left dark color="primary">mdi-arrow-left-circle</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                      <v-btn small
                            :disabled="$store.state.loading"
                            color="primary"
                            depressed
                            @click="validaNextP1"> {{btnSiguienteP1}}
                    </v-btn>
                    <v-btn v-if="bBtnCerrarPagar"  text @click="cerrarPropuestaPago">
                      <v-icon large right dark color="primary">mdi-close-box</v-icon>
                      </v-btn>
                    <v-btn small v-if="casoPagar3" color="success" depressed  @click="prePagarDespues">pagar despues</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    <!-- </v-row> -->

    <!--propuesta mas tarde-->
    <v-row justify="center">
        <v-dialog v-model="$store.state.dialogPropuestaMasTarde" persistent max-width="600px">
            <v-card class="mx-auto">

                <v-window v-model="stepx">
                    <v-window-item :value="1">
                        <Propuesta0></Propuesta0>
                    </v-window-item>

                    <v-window-item :value="2">
                        <Propuesta3></Propuesta3>
                    </v-window-item>

                </v-window>

                <v-divider></v-divider>

                <v-card-actions>
                    <v-btn :disabled="stepx === 1" v-if ="stepx > 1"  text @click="stepx--">Atras</v-btn>
                    <v-spacer></v-spacer>
                      <v-btn
                            :disabled="$store.state.loading"
                            color="primary"
                            depressed
                            @click="validaNextx"> {{btnSiguientex}}
                    </v-btn>
                    <v-btn v-if="bBtnCerrarMasTarde"  text @click="terminaProcesoMasTarde">Cerrar</v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </v-row>

    <div class="text-center">
    <v-snackbar v-model="snackbar" :vertical="vertical" color="error" :timeout="timeoutMsg" style="text-align: center;font-size:20px!important">
      {{ textError }}
      <v-btn color="indigo" text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </div>

 <v-dialog v-model="dialogoFinalSinPagar" persistent max-width="450px" style="margin-top: -10px;"> 
      <v-card height="200">
        <div id="loginRespuesta"  
        style="margin-top:20px;position:absolute;text-align:center;color:#227aff;
        font-family:'Arial',Times, serif;margin-left: 20px;word-break: break-word;line-height: 1.5rem;
        font-size:20px;width:90%;font-weight:bold;">Al aceptar, se enviará un correo con la información de su propuesta.</div>
        <div style="height:80px"></div>
        <v-card-text style="margin-top:0px">
          <v-form  style="max-width:400px;margin-top:-10px" ref="form" v-model="valid" :lazy-validation="lazy">
          <v-container>
            <v-row>
               <v-col  cols="6" style="text-align:right;width:100%">
                <v-btn rounded class="mr-1" color="red" 
                style="width:110px;font-size:21px;margin-top:20px;color:white;text-transform: capitalize;display: inline-block;" 
                @click="pagaDespues"
                >Aceptar</v-btn>
               </v-col>

                <v-col cols="6">
                  <div style="text-align: left;">
                    <v-btn rounded class="mr-1" color="blue"  @click="dialogoFinalSinPagar=false"
                    style="width:110px;font-size:21px;margin-top:20px;color:white;text-transform: capitalize;display: inline-block;" 
                    >Cancelar</v-btn>
                    </div>
                </v-col>
            </v-row>
          </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>


  <v-dialog v-model="dialogoLogin" persistent max-width="450px" style="margin-top: -10px;"> 
      <v-card >
        <div id="loginRespuesta"  
        style="margin-top:20px;position:absolute;text-align:center;color:#227aff;
        font-family:'Arial',Times, serif;margin-left: 20px;word-break: break-word;line-height: 1.5rem;
        font-size:20px;width:90%;font-weight:bold;">{{loginRespuestaMsg}} </div>
        <v-card-text>
          <v-form  style="max-width:400px;margin-top:-10px" ref="form" v-model="valid" :lazy-validation="lazy">
          <v-container>
            <v-row>
              <v-col cols="12">
               
                <v-text-field  style="margin-top:48px;border-radius: 8px;" 
                label="Correo electrónico" 
                v-model="correo"
                ref="correoRef"
                focusable
                maxlength="60"
                hint="correo@domain.com"
                :rules="rulesCorreo"
                filled  dense  hide-details="auto"
                background-color="#f0f8ff"
                 ></v-text-field>

                 <v-text-field  style="margin-top:8px;border-radius: 8px;" 
                label="Contraseña" 
                :type="show1 ? 'text' : 'password'"
                v-model="contrasenia"
                maxlength="12"
                hint="Ingrese su contraseña"
                  filled  dense :rules="rulesContrasenia" hide-details="auto"
                background-color="#f0f8ff"
                 ></v-text-field>
              </v-col>
         
            </v-row>
            <v-row>
               <v-col  cols="6" style="text-align:right;width:100%">
              <v-btn rounded class="mr-1" color="red" 
              style="width:110px;font-size:21px;margin-top:20px;color:white;text-transform: capitalize;display: inline-block;" 
              :disabled="!valid" @click="loginx"
              >Aceptar</v-btn>

           
          </v-col>
          <v-col cols="6">
             <div style="text-align: left;">
              <v-btn rounded class="mr-1" color="blue"  @click="salir"
              style="width:110px;font-size:21px;margin-top:20px;color:white;text-transform: capitalize;display: inline-block;" 
              >Cancelar</v-btn>
              </div>
          </v-col>
            

            </v-row>
          </v-container>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>

 </v-container>   
  
</template>

<script>
import router from '../router';
import { VueMaskDirective   } from 'v-mask'
import CreditCardForm from './CreditCardForm'
import stepPropuesta from './StepPropuesta'
import Propuesta0 from './Propuesta00';
import Propuesta from './Propuesta01';
import Propuesta2 from './Propuesta02';
import Propuesta3 from './Propuesta03';
import Final from './Final';
import Procesando from './Procesando'
import Fiscales from './Fiscales01';
import Propiedades from './Propiedades02';
import Cotizacion from './Cotizacion';
import Todos from './Todos';
import operations from '../operations'

  export default {
     directives: {
      'mask': VueMaskDirective
    },
    components:{
     CreditCardForm,Propuesta,Propuesta0,Propuesta2,Propuesta3,stepPropuesta,Final,Procesando,Fiscales,Propiedades,Cotizacion,Todos
    },
    name: 'Proceso',
    data: () => ({
      dialogoFinalSinPagar:false,
      loginRespuestaMsg:'Ingrese sus credenciales como medida de seguridad',
      casoPagar3:false,
      contrasenia:'',
      rulesContrasenia: [
        value => !!value || 'Requerido.',
        value => (value && value.length >= 3) || 'Min 8 caracteres',
      ],
      show1: false,
      correo:'',
      rulesCorreo: [
        value => !!value || 'Requerido.',
        value => /.+@.+\..+/.test(value) || 'El correo debe ser válido',
      ],
      valid: true,
      lazy: false,
      dialogoLogin:false,
      procesando:true,
      timeoutMsg:4000,
      snackbar: false,
      textError: '',
      vertical: true,
      bTarjetaValida:false,
      steppagar:0,
      siguienteOK:true,
      bBtnCerrarPagar:true,
      bBtnCerrarMasTarde:true,
      step: 1,
      stepx: 1,
      dialogPropuesta:false,
      dialogPropuestaMasTarde:false,
      bAbreForma2:false,
      bAbreForma3:false,
      propiedades:'',
      noPropiedades: ['01', '02', '03', '04','05','06','07','08','09','10','11', '12', '13', '14','15','16','17','18','19','20','21', '22', '23', '24','25','26','27','28','29','30','31', '32', '33', '34','35','36','37','38','39','40','41', '42', '43', '44','45','46','47','48','49','50','51', '52', '53', '54','55','56','57','58','59','60','61', '62', '63', '64','65','66','67','68','69','70','71', '72', '73', '74','75','76','77','78','79','80','81', '82', '83', '84','85','86','87','88','89','90','91', '92', '93', '94','95','96','97','98','99'],
      rfc:'',
      rulesRFC: [
        value => !!value || 'Requerido.',
        value => (value && value.length >= 15) || 'El RFC debe ser de 12 dígitos o 13 dígitos (XXXX 0000 PK5)',
      ],
      frm2Propiedades:false,
      frm1ConRFC:false,
      frm1SinRFC:false,
      fondoRFC:"white",
      fondoPropiedades:'white',
      tieneFIEL:null,
      tieneRFC: null,
      colorTitulo1:'tituloAzul',
      colorTitulo2:'tituloGris',
      colorTitulo3:'tituloGris',
      fiscalesIcono:'https://utaxon.com/img/DATOS-FISCALES.png',
      propiedadesIcono:'https://utaxon.com/img/PROPIEDADES-GRIS.png',
      cotizacionIcono:'https://utaxon.com/img/COTIZACION-GRIS.png',
      separador1:true,
      separador2:true,
      hover:'',
      windowWidth: 0,
      windowHeight: 0,
      stepBox1: 1,
      stepBox2: 2,
      stepBox3: 3,
      escenario:0,
      nextEscenario1:false,
      nextEscenario1Back:false,
      nextEscenario2:false,
      frm1OK:false,
      frm2OK:false,
      frm3OK:false,
          }),
     mounted() {
      this.$nextTick(function() {
        window.addEventListener('resize', this.getWindowWidth);
        window.addEventListener('resize', this.getWindowHeight);
        //Init
        this.getWindowWidth()
        this.getWindowHeight() 


      })
    },
    methods: {
      pagarDespuesX(){

      },
      cerrarPropuestaPago(){
        this.$store.state.dialogPropuesta=false;
        this.steppagar=1;
      },
      async loginx () {
         try{
             this.$store.state.cliente.password=this.contrasenia;
             this.$store.state.cliente.email=this.correo;
             
              const response = await operations.login({ correo: this.correo, contrasenia: this.contrasenia });
              console.log(response);
              if(response.status == "200"){
                 console.log("ok");
                 console.log(response);

                 // if(response.data.propuestaId != ''){
                    //ya tiene contrato. Flujo de un cliente con contrato existente
                  //  router.push('/dashboard');
                 //}else{
                    //nuevo proceso. Flujo normal
                    this.$store.state.sesion.email=this.correo;
                    this.$store.state.sesion.step="proceso";
                    this.$store.state.cliente.celular= response.data.telefono == null ? '0000000000' : response.data.telefono;
                    this.$store.state.cliente.nombre= response.data.nombre == null ? 'Nombre' : response.data.nombre;
                    
                    //quita dialogo de login
                    this.dialogoLogin=false;
                    //router.push('/proceso');
                 //}
                 
                
              }
              if(response.status == "204"){
                 console.log("204");
                 //document.getElementById('loginRespuesta').innerText="Las credenciales ingresadas no existen";
                 this.loginRespuestaMsg="Las credenciales ingresadas no existen";
                 document.getElementById('loginRespuesta').style.fontSize ='20px';   
              }else{
                //document.getElementById('loginRespuesta').innerText="Las credenciales ingresadas no existen";
                this.loginRespuestaMsg="Las credenciales ingresadas no existen";
                document.getElementById('loginRespuesta').style.fontSize ='20px';
              }
         }
         catch(error){
              console.log(error.response);
              console.log(error.response.data);
              
              if(error.response.status == 500){
                 document.getElementById('loginRespuesta').style.fontSize ='20px';
                    if(error.response.data.response=="No coinciden las credenciales"){
                      //document.getElementById('loginRespuesta').innerText="Credenciales inválidas";
                      this.loginRespuestaMsg="Credenciales inválidas";
                    }else if( (error.response.data.response).startsWith("could not extract")){
                      //document.getElementById('loginRespuesta').innerText="El servicio esta temporalmente inactivo";
                      this.loginRespuestaMsg="El servicio esta temporalemnte inactivo";
                    }else{
                      //document.getElementById('loginRespuesta').innerText="El usuario no esta activo en la plataforma";
                      this.loginRespuestaMsg="El usuario no esta activo en la plataforma";
                    } 
              }else{
                   // document.getElementById('loginRespuesta').innerText=error.response.data.response;
                    this.loginRespuestaMsg=error.response.data.response;
              }
         }
           
      },
      salir() {
        console.log("quita login form");
        //document.getElementById('loginRespuesta').innerText="Ingrese sus credenciales para proceder con el pago";
        this.loginRespuestaMsg="Ingrese sus credenciales como medida de seguridad";
                this.contrasenia="";
                this.correo="";
        //quita dialogo de login
        this.dialogoLogin=false;
                   
      },
      atrasPagar(){
        console.log("atras pagar...");
        console.log(this.steppagar);
        this.$store.state.loading=false;
        this.casoPagar3=false;
       this.steppagar--;
      },
      terminaProcesoMasTarde(){
        
        this.$store.state.dialogPropuestaMasTarde=false
        
      },
      validaNext() {
       
       this.$store.state.loading=true;
            this.step++;
            //console.log("next::"+this.step);
            if(this.step==2){
              this.$store.state.cliente.propiedades= null;
            }

            if (this.step > 4) {
                this.dialogPropuesta = false;
                //router.push('/');
                window.location = 'https://www.utaxme.com';
            }
        },
       validaNextP1() {
         //debugger;
         //next pago TC
           this.$store.state.loading=false; 
          this.casoPagar3=false;
            this.steppagar++;
            console.log("next::"+this.steppagar);
            if(this.steppagar==2){
              console.log("paso 2");
               this.guardaQuestionario();
            }

            
           //show tc form
            if(this.steppagar==3){
               this.$store.state.loading=true;
               console.log("paso 3");
               //valida si TC esta completo
               this.validaTC();
               
            }
            
            if(this.steppagar==4){
              console.log("paso 4");
              // this.$store.state.cliente.email='';
              // //validar que el correo exista!!
              // if(this.$store.state.cliente.email==null){
              //   this.dialogoLogin=true;
              // }
              // else if(this.$store.state.cliente.email==''){
              //   this.dialogoLogin=true;  
              // }else{
              //   this.generaToken();
              // }
              this.generaToken();

              
            }

            if (this.steppagar > 4) {
                this.$store.state.dialogPropuesta = false;
                console.log("...caso pagar");
                window.location = 'https://www.utaxme.com';
            }
        },
      validaTC(){
           if(this.$store.state.cliente.cardYear!='Año' && this.$store.state.cliente.cardMonth!='Mes' && this.$store.state.cliente.cardCvv!='' && 
                this.$store.state.cliente.cardNumber >=16 &&
                this.$store.state.cliente.cardName.length > 5){
                this.$store.state.loading=false;
                console.log("si cumple");
           }else{
                 this.$store.state.loading=true;
                 console.log("no cumple");
           }         
      },  
      generaToken(){
         console.log('peticion token...');
       Conekta.setPublicKey('key_Wg73TCokv6Z4S2FfcdocGzw'); //key_GVxxkvvSEr5NyGNMyes7AhQ');

       var tokenParams = {
            "card": {
              "number": this.$store.state.cliente.cardNumber,
              "name": this.$store.state.cliente.cardName,
              "exp_year": this.$store.state.cliente.cardYear,
              "exp_month": this.$store.state.cliente.cardMonth,
              "cvc": this.$store.state.cliente.cardCvv,
              "address": {
                  "street1": "Calle 123 Int 404",
                  "street2": "Col. Condesa",
                  "city": "Ciudad de Mexico",
                  "state": "Distrito Federal",
                  "zip": "12345",
                  "country": "Mexico"
              }
            }
          };
        // Previene hacer submit más de una vez
        //$form.find("button").prop("disabled", true);
        Conekta.Token.create(tokenParams, this.conektaSuccessResponseHandler, this.conektaErrorResponseHandler);
      },
      conektaSuccessResponseHandler(token){
        console.log('genere token exitoso');
        console.log(token.id);
        this.$store.state.cliente.tokenPublico=token.id;
        
        this.procesoPago();
      },
      conektaErrorResponseHandler(response){
         console.log('error al generar token');
         console.log(response);
         this.$store.state.cliente.tokenPublico='';
         
      

            setTimeout(() => {
            this.textError='';
            this.snackbar=true;

            if(response.message_to_purchaser=="Your code could not be processed, please try again later")
            this.textError="El código no puede ser procesado, intente más tarde";
            else
            this.textError=response.message_to_purchaser;

            this.steppagar=3;
            }, 500);

         
      },
      getNoPropiedades(){
        if(this.$store.state.cliente.propiedades=='PAQUETE BÁSICO'){
            // state.cliente.propiedades=1;
              return 1;
            }
            else if(this.$store.state.cliente.propiedades=='PAQUETE AVANZADO'){
              //state.cliente.propiedades=2;
              return 2;
            }
            else if(this.$store.state.cliente.propiedades=='PAQUETE PREMIUM'){
              //state.cliente.propiedades=3;
              return 3;
            }
            else{
              //state.cliente.propiedades=0;
              return 0;
            }
      },
      async procesoPago(){           
           try{
              //Registra cuenta en sistema
              console.log("data bank...");
              const responseAltaBank = await operations.dataBank({ 
                  anioExpira:this.$store.state.cliente.cardYear,
                  cvt:this.$store.state.cliente.cardCvv,
                  mesExpira:this.$store.state.cliente.cardMonth,
                  tarjeta:this.$store.state.cliente.cardNumber,
                  correo: this.$store.state.cliente.email
                });
             
                console.log(responseAltaBank);
              if(responseAltaBank.status == "200"){
                 console.log("Success");
                 //------------------REGISTRA TRANSACCION BANCARIA---------------------------
                 try{//
                    console.log("transaccion bancaria...");
                    var monto = this.$store.getters.getCalculoTotal.replace(",","");
                    //var monto = (this.$store.state.cliente.total.toString()).replace(",","");
                    const responseTransaction = await operations.transaccion({ 
                      cantidad: 1,
                      celular: this.$store.state.cliente.celular,
                      descripcion:'Paquete de Servicios para Anfitriones de Airbnb',
                      email: this.$store.state.cliente.email,
                      idProducto: "1",
                      monto: parseFloat(monto).toFixed(2),
                      tokenPublico: this.$store.state.cliente.tokenPublico,
                      username: this.$store.state.cliente.email,
                    });

                    console.log('respuesta transaccion:');
                    console.log(responseTransaction);
                    if(responseTransaction.status == "200"){
                        this.obtieneFolio();
                        this.procesando=false;
                        //CASO PAGA AHORA------------------------------
                        // console.log("paga Ahora...");
                        // this.pagaAhora();
                        //---------------------------------------------
                    }
                 }
                 catch(error){
                    console.log('error transaccion:');
                    console.log(error);
                    console.log(error.response);
                    if( (error.response.data.response).startsWith("El parametro")){
                       this.dialogoLogin=true;
                       this.steppagar=3;
                    }else{
                      this.textError='';
                      this.snackbar=true;
                      this.textError=error.response.data.response;
                      this.steppagar=3;
                    }
                    
                    
                 }
                 //------------------------------------------------------------------------------
              }
              else if(responseAltaBank.status == "204"){
                 console.log("204");
                  
              }else{
               console.log("otro codigo");
              }
           }catch(error){
             console.log(error);
             console.log(error.response.data);
             this.steppagar=3;
             //alert(error.response.data.response);
               if(error.response.status == 500){
                    if(error.response.data.response=="No coinciden las credenciales"){
                      
                    }else if( (error.response.data.response).startsWith("could not extract")){
                      
                    }else{ //if( (error.response.data.response).startsWith("El usuario no está registrado")){
                       this.dialogoLogin=true;
                    } 
              }
           }  
        },
      async pagaAhora(){
        console.log("paid off...");
        try{

            const responsePaidOff = await operations.paidOff({ 
              username: this.$store.state.cliente.email
            });
            
            console.log('respuesta responsePaidOff:');
            console.log(responsePaidOff);
            
          }
          catch(error){
            console.log('error transaccion responsePaidOff:');
            console.log(error);
            console.log(error.response);
             //---------------NOTIFY TO USER---------------------------------------------
                  

             //-------------------------------------------------------------------------------
          }
      }, 
      async obtieneFolio(){
         try{
           console.log("obtiene folio..");
              const response = await operations.login({ correo: this.$store.state.cliente.email, 
                                                        contrasenia: this.$store.state.cliente.password });
              
              console.log("asignando folio .."+ response.data.folio);
              this.$store.state.cliente.folio =  response.data.folio;
              console.log(response);
              if(response.status == "200"){
                console.log("obtiene folio ok..");
                 console.log(response);
                 


                setTimeout(() => {
                  console.log("paga Ahora...");
                 this.pagaAhora();
                }, 400);

                 
              }
              if(response.status == "204"){
                console.log("obtiene folio..204");
                 console.log("204");
                 //document.getElementById('loginRespuesta').innerText="Las credenciales ingresadas no existen";
              }
         }
         catch(error){
              console.log("obtiene folio error..");
              console.log(error.response);
              console.log(error.response.data);
              
              if(error.response.status == 500){
                console.log("obtiene folio..500");
                    if(error.response.data.response=="No coinciden las credenciales"){
                      //document.getElementById('loginRespuesta').innerText="Credenciales inválidas";
                    }else if( (error.response.data.response).startsWith("could not extract")){
                      console.log("obtiene folio..extract");
                     // document.getElementById('loginRespuesta').innerText="El servicio esta temporalmente inactivo";
                    }else{
                      console.log("obtiene folio..no activo");
                      //document.getElementById('loginRespuesta').innerText="El usuario no esta activo en la plataforma";
                    } 
              }else{
                console.log("obtiene folio..otro");
                    //document.getElementById('loginRespuesta').innerText=error.response.data.response;
              }
         }
      }, 
      async guardaQuestionario(){
        //debugger;
         try{
           console.log("guarda questionario...");
           var calcTotal = this.$store.getters.getCalculoTotal.replace(",","");
           var calcSubTotal = this.$store.getters.getDesde.replace(",","");

            const responseQuestionary = await operations.questionary({ 
              ciudad: 0,
              comentarios: "",
              email: this.$store.state.cliente.email,
              estado: 0,
              idCuestionario: 0,
              idProspecto: 0,
              ivaCotizacion: parseFloat(this.$store.getters.getIVADesde),
              numPropiedades:this.getNoPropiedades(),
              numUsuarios: 0,
              pagarDespues: true,//this.$store.state.cliente.mastarde,
              paisOrigen: 0,
              paqueteId: this.getNoPropiedades(),
              propuestaId: 0,
              resideMexico: true,
              rfc: this.$store.state.cliente.rfc,
              subtotalCotizacion: parseFloat(calcSubTotal),
              tieneFIEL: this.$store.state.cliente.tieneFIEL,
              tieneRFC: this.$store.state.cliente.tieneRFC,
              totalCotizacion: parseFloat(calcTotal)
            });
            
            console.log('respuesta guarda questionario:');
            console.log(responseQuestionary);
            if(responseQuestionary.status == "200"){
                //---------------SEND FINAL EMAIL---------------------------------------------
                  //  if(this.$store.state.cliente.mastarde==false){
                  //    //CASO PAGA AHORA------------------------------
                  //    console.log("pagaAhora...");
                  //    // this.pagaAhora();
                  //    //--------------------------------------------
                  //  }else{
                  //    //CASO PAGA DESPUES
                  //    console.log("pagaDespues...");
                  //     //this.pagaDespues();
                  //  }
                //-------------------------------------------------------------------------------
            }
          }
          catch(error){
            console.log('error transaccion:');
            console.log(error);
            console.log(error.response);
             //---------------NOTIFY TO USER---------------------------------------------
                  

             //-------------------------------------------------------------------------------
          }
      }, 
      async guardaQuestionarioCaso2(){
        //debugger;
         try{
            console.log("guarda questionario caso2...");
            const responseQuestionary = await operations.questionary({ 
              ciudad: 0,
              comentarios: "",
              email: this.$store.state.cliente.email,
              estado: 0,
              idCuestionario: 0,
              idProspecto: 0,
              ivaCotizacion: parseFloat(this.$store.getters.getIVADesde),
              numPropiedades:this.$store.state.cliente.propiedades,
              numUsuarios: 0,
              pagarDespues: this.$store.state.cliente.mastarde,
              paisOrigen: 0,
              paqueteId: this.getNoPropiedades(),
              propuestaId: 0,
              resideMexico: true,
              rfc: this.$store.state.cliente.rfc,
              subtotalCotizacion: parseFloat(this.$store.getters.getDesde),
              tieneFIEL: this.$store.state.cliente.tieneFIEL,
              tieneRFC: this.$store.state.cliente.tieneRFC,
              totalCotizacion: parseFloat(this.$store.getters.getCalculoTotal)
            });
            
            console.log('respuesta guarda questionario:');
            console.log(responseQuestionary);
            if(responseQuestionary.status == "200"){
                 //CASO PAGA DESPUES
                 console.log("pagaDespues...");
                 this.pagaDespues();
                //-------------------------------------------------------------------------------
            }
          }
          catch(error){
            console.log('error transaccion:');
            console.log(error);
            console.log(error.response);
             //---------------NOTIFY TO USER---------------------------------------------
                  

             //-------------------------------------------------------------------------------
          }
      }, 
      prePagarDespues(){
       if(this.$store.state.cliente.email==''){
         this.dialogoLogin=true;
       }else{
         this.dialogoFinalSinPagar=true;
       }
      },
      async pagaDespues(){

        //debugger;
        try{
          console.log("pending...");
            const responsePending = await operations.pending({ 
              username: this.$store.state.cliente.email
            });
            console.log('respuesta Pending:');
            console.log(responsePending);

            if(responsePending.status == "200"){
              window.location = 'https://www.utaxme.com';
            }else{
               console.log('respuesta Pending con error:');
            console.log(responsePending);
            }
            
          }
          catch(error){
            console.log('error transaccion Pending:');
            console.log(error);
            console.log(error.response);
             //---------------NOTIFY TO USER---------------------------------------------
             if(error.response.data.response=="Usuario no registrado en la plataforma"){
                
               this.dialogoLogin=true;
               //console.log(document.getElementById('loginRespuesta'));
               //document.getElementById('loginRespuesta').innerText = "Ingrese sus credenciales para terminar";
               this.loginRespuestaMsg="Ingrese sus credenciales para terminar";
             }
             //--------------------------------------------------------------------------
          }
      },
      validaBack() {
            //this.loader2 = 'loading';
       console.log("regreso::"+this.step);
            if(this.step==3 || this.step==2 || this.step==4){
              console.log("habilita boton");
              this.siguienteOK=true;
              this.$store.state.loading=false;
              //this.$store.state.cliente.propiedades= null;
            }
            this.step--; 
        },
      validaNextx() {
        //debugger;
        //next de caso sin pagar
            this.$store.state.loading=false;
            this.stepx++;
            console.log(this.stepx);

            
            if (this.stepx > 2) {
                this.$store.state.dialogPropuestaMasTarde = false;
                 //TODO
                //send data to server
                //questionary
                //---------------GUARDA QUESTIONARIO---------------------------------------------
                console.log("guarda questionario caso 2");
                this.guardaQuestionarioCaso2();
                //-------------------------------------------------------------------------------

                //send mail

                console.log("enviando al servidor....caso mas tarde");
                //router.push('/');
                
            }
        },
      abreForma2(){
        //console.log("abre forma 2");
        this.bAbreForma2=true;
        this.propiedades='';
        this.frm1OK=true;
        this.frm2OK=false;
        this.frm3OK=false;
      },
      cierraForma2(){
        //console.log("cierra forma 2");
        this.bAbreForma2=false;
        this.frm1OK=false;
        this.frm2OK=false;
        this.frm3OK=false;
      },
      abreForma3(){
        //console.log("abre forma 3");
        this.bAbreForma3=true;
        this.frm1OK=false;
        this.frm2OK=true;
        this.frm3OK=false;
      },
      cierraForma3(){
        this.bAbreForma3=false;
      },
      propiedadSeleccionada(){
          this.$store.state.cliente.propiedades= this.propiedades;
          
      },
      validaForma1(valor){
        if(valor=="si"){
          this.fiscalesIcono='https://utaxon.com/img/DATOS-FISCALES-GRIS.png';
          this.colorTitulo1='tituloGris';
          this.frm1SinRFC=false;
          this.tieneFIEL='';
          this.rfc='';
          this.cierraForma2();
          this.cierraForma3();
        }
        else{
          //no tiene rfc, avanza
          this.fiscalesIcono='https://utaxon.com/img/DATOS-FISCALES.png';
          this.colorTitulo1='tituloAzul';
          this.frm1SinRFC=true;
          this.abreForma2();
        }

      },
      validaForma1b(valor){

       this.tieneFIEL=valor;
       
        if(this.rfc.length>=15){
          this.fondoRFC="#f0f8ff";
          this.colorTitulo1='tituloAzul';
          this.fiscalesIcono='https://utaxon.com/img/DATOS-FISCALES.png';
          this.tieneRFC="si";
          
        }else{
          this.fondoRFC="white";
        }
        this.validaforma1Completa();
      },
      setRFC(){
          console.log(event.key);
            var regex = new RegExp("^[a-zA-Z0-9]+$");
            var key = event.key;//String.fromCharCode(!event.key ? event.which : event.charCode);
            if (!regex.test(key)) {
              this.rfc=this.rfc.replace(event.key,'')
              event.preventDefault();
              return false;
            }

            this.rfc=this.rfc.toUpperCase();
            this.$store.state.cliente.rfc= this.rfc;
            if(this.rfc.length>=15){
              this.fondoRFC="#f0f8ff";
             // this.validaforma1Completa();
            }
            this.validaforma1Completa();
        },
      validaforma1Completa(){
          if(this.tieneRFC=="si" && this.rfc.length>=15 && this.tieneFIEL !=null){
              this.frm1ConRFC=true;
              this.fiscalesIcono='https://utaxon.com/img/DATOS-FISCALES.png';
              this.colorTitulo1='tituloAzul';
              this.abreForma2();
          }else{
            this.frm1ConRFC=false;
            this.fiscalesIcono='https://utaxon.com/img/DATOS-FISCALES-GRIS.png';
            this.colorTitulo1='tituloGris';
            this.cierraForma2();
            this.cierraForma3();
          }
      },
      tieneRFCM(){
        //console.log("seleccionado:"+this.tieneRFC);
          
      },
      controlStep(forma, avanza){
         if(forma==1){
           this.stepBox1=this.stepBox1+avanza;
           this.stepBox2=this.stepBox2+avanza;
           this.frm1OK=false;
           this.propiedades='';
         }
         
         if(forma==2){
           this.stepBox1=this.stepBox1+avanza;
           this.stepBox2=this.stepBox2+avanza;

           if(avanza>0){
           this.frm2OK=true;
           this.frm1OK=false;
           }

           if(this.stepBox2==4){
           this.frm3OK=true;
           this.frm1OK=false;
           this.frm2OK=false;
           }
         }

         if(forma==3){
           this.stepBox1=this.stepBox1+avanza;
           this.stepBox2=this.stepBox2+avanza;

           if(avanza==-1){
           this.frm2OK=true;
           this.frm1OK=false;
           this.frm3OK=false;
           }
          
           
         }


      },
      caso1EnLinea(){
         //console.log("Caso 1 en linea");
         this.escenario=1;
         this.nextEscenario1=true;
         this.nextEscenario1Back=true;
         this.nextEscenario2=false;

      },
      caso2EnLinea(){
         //console.log("Caso 2 en linea");
         this.escenario=2;
         this.nextEscenario1=false;
         this.nextEscenario1Back=true;
         this.nextEscenario2=true;

      },
      caso3EnLinea(){
        //console.log("Caso 3 en linea");
        this.escenario=3;
        this.nextEscenario1=false;
        this.nextEscenario1Back=false;
        this.nextEscenario2=false;

      },
      getWindowWidth(event) {
          this.windowWidth = document.documentElement.clientWidth;
          //console.log("windowWidth::"+this.windowWidth);

          this.$store.state.mobile=false;
          this.$store.state.tablet=false;
          this.$store.state.pc=false;
          if(this.windowWidth < 699){
            this.$store.state.mobile=true;
          }
          // if(this.windowWidth > 600 && this.windowWidth <= 959){
          //   this.tablet=true;
          // }
          if(this.windowWidth > 700){
            this.$store.state.pc=true;

          }
        
          //------------------------------------------------------------

        },

        getWindowHeight(event) {
         
          
          
         // console.log("alto::"+document.documentElement.clientHeight);
         // document.getElementById("rowContenedor").style.height=this.windowHeight+"px";
         // document.getElementById("rowContenedor").style.border="dotted";
        }
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.getWindowWidth);
      window.removeEventListener('resize', this.getWindowHeight);
      document.body.removeAttribute("class","noscroll");
    },
    created(){
      console.log(this.$store.state.cliente.email);
      this.$store.state.cliente.cardYear='';
      this.$store.state.cliente.cardMonth='';
      this.$store.state.cliente.cardCvv='';
    },
    computed: {
      btnSiguienteP1(){

        this.siguienteOK=true;

            if(this.steppagar >= 2 && this.steppagar < 4){
               // console.log("entro a prenderlo");
              //show mas tarde option 
              this.btnMasTardeVisible=true; 
            }else{
                this.btnMasTardeVisible=false; 
            }
           // console.log(this.btnMasTardeVisible);
           this.bBtnCerrarPagar=true;
            if(this.steppagar <2)return "Siguiente";
            if(this.steppagar == 2)return "Siguiente";
            if(this.steppagar == 3){    
               this.casoPagar3=true;
               this.bBtnCerrarPagar=false;
              return "Pagar";
              }
            // if(this.steppagar == 4){
            //   this.siguienteOK=false;
            // }
            if(this.steppagar == 4){
              this.bBtnCerrarPagar=false;
              return "Cerrar";   }
        },
      btnSiguiente(){

        this.siguienteOK=true;

            if(this.step >= 2 && this.step < 4){
               // console.log("entro a prenderlo");
              //show mas tarde option 
              this.btnMasTardeVisible=true; 
            }else{
                this.btnMasTardeVisible=false; 
            }
           // console.log(this.btnMasTardeVisible);
           this.bBtnCerrarPagar=true;
            if(this.step <2)return "Siguiente";
            if(this.step == 2)return "Siguiente";
            if(this.step == 3){
              this.siguienteOK=false;
            }
            if(this.step == 4){
              this.bBtnCerrarPagar=false;
              return "Cerrar";   }
        },
        btnSiguientex(){
           
            if(this.stepx <2){
              this.bBtnCerrarMasTarde=true;
              return "Siguiente";}
            if(this.stepx == 2){
              this.bBtnCerrarMasTarde=false;
              return "Enviar";
              }  
        },
      
      
      getCalculoDesde(){
        this.$store.state.cliente.subtotal=this.$store.getters.getDesde;
        return this.$store.getters.getDesde;
      },
      getSesion(){
       return this.$store.getters.getSesion;
      },
      getCalculoTotal(){
        this.$store.state.cliente.total=this.$store.getters.getCalculoTotal;
         return this.$store.getters.getCalculoTotal;
      },
      getIVADesde(){
        this.$store.state.cliente.iva=this.$store.getters.getIVADesde;
       return this.$store.getters.getIVADesde;
      },
      getCliente(){
          return this.$store.getters.getCliente;
      },
      currentTitleBox1 () {
        switch (this.stepBox1) {
          case 1: return 'Datos fiscales'
          case 2: return 'Propiedades'
          default: return 'Cotización'
        }
      },
      currentTitleBox2 () {
        switch (this.stepBox2) {
          case 1: return 'Datos fiscales'
          case 2: return 'Propiedades'
          default: return 'Cotización'
        }
      },
      currentTitleBox3 () {
        switch (this.stepBox3) {
          case 1: return 'Datos fiscales'
          case 2: return 'Propiedades'
          default: return 'Cotización'
        }
      },
    },
    beforeCreate: function() {
    document.getElementsByTagName("body")[0].className="noscroll"; 
  },
  }
</script>

<style scoped>
.tituloGris{
  color:#c7c3c3;
}

.tituloAzul{
  color:#013872;
}

 input[type='radio']:after {
        width: 22px;
        height: 22px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: white;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 9px solid #d1d3d1;
    }

    input[type='radio']:checked:after {
        width: 22px;
        height: 22px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: white;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 9px solid #013872;
    }



</style>