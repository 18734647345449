<template>
     <v-card id="fiscales" flat class="mx-auto" style="margin-top:80px"   >
    <v-container style="height:100%">
      <v-row dense style="height:25%">
        <v-col cols="12" >
          <v-card elevation="0" style="height:80px" color="white">
            <v-card-title class="title font-weight-regular justify-space-between;">
            <v-img style="height:40px!important;width:50px!important;transition: width 1s;margin-top:-25px" contain :src="fiscalesIcono" aspect-ratio="1"></v-img>  
            <span :class="colorTitulo1" style="font-family:'Arial',Times,serif;font-size:20px;font-weight:bold;
                                               width: 100%;text-align: center;">Datos fiscales</span>
          </v-card-title>
          </v-card>
        </v-col>
       </v-row>

       <v-row dense style="height:20%">
        <v-col cols="12" >
          <v-card elevation="1" style="height:60px" color="white">
            <v-card-title class="title font-weight-regular justify-space-between;">
            <span  style="font-family:'Arial',Times,serif;font-size:18px;font-weight:bold;margin-top:-30px;
                                               width: 100%;text-align: center;">¿Tienes RFC?</span>
          <table style="margin-top:-9px;text-align: center;font-family:'Arial',Times,serif;font-size:14px;font-weight:bold;width: 100%;">
                      <tr>
                        <td><input style="cursor:pointer" type="radio" id="uno" value="si" v-model="tieneRFC" @click="validaForma1('si')"></td>
                        <td><input style="cursor:pointer" type="radio" id="dos" value="no" v-model="tieneRFC" @click="validaForma1('no')"></td>
                      </tr>
                       <tr>
                          <td><label  for="uno" style="margin-top: -15px;position: absolute;margin-left: -4px;">Si</label></td>
                          <td><label  for="dos" style="margin-top: -15px;position: absolute;margin-left: -4px;">No</label></td>
                      </tr>
                    </table>
          </v-card-title>
          </v-card>
        </v-col>
        </v-row>

      <div style="height:5%"></div>
       <v-row dense style="height:20%">
        <v-col cols="12" v-if="tieneRFC=='si'"  >
          <v-card elevation="0" style="height:95px" color="white">
            <v-card-title class="title font-weight-regular justify-space-between;">
            <span  style="font-family:'Arial',Times,serif;font-size:18px;font-weight:bold;margin-top:-20px;
                                               width: 100%;text-align: center;">Coloca RFC</span>
            <div  style="margin-top:-10px;text-align: center;width:100%">
                      <v-text-field  style="margin-top:8px;border-radius: 8px;" 
                      label="RFC" 
                      v-model="rfc" solo
                      maxlength="15"
                      v-mask="'XXXX ###### XXX'"
                      @keyup="setRFC"
                      dense :rules="rulesRFC"  hide-details="auto"
                      :background-color="fondoRFC"
                    ></v-text-field>
          </div>
          </v-card-title>
          </v-card>
        </v-col>
        </v-row>

      <div style="height:5%"></div>

       <v-row dense style="height:20%">
        <v-col cols="12" v-if="tieneRFC=='si'" style="margin-top:-10px;">
          <v-card elevation="1" style="height:80px" color="white">
            <v-card-title class="title font-weight-regular justify-space-between;">
            <span  style="font-family:'Arial',Times,serif;font-size:18px;font-weight:bold;margin-top:-20px;
                                               width: 100%;text-align: center;">¿Tienes FIEL?</span>
            <!-- <div  style="margin-top:-10px;text-align: center;width:100%">
              <v-radio-group v-model="tieneFIEL" row style="display: inline-block;">
              <v-radio label="Si" value="si" @change="validaFIEL"></v-radio>
              <v-radio label="No" value="no" @change="validaFIEL"></v-radio>
          </v-radio-group>
          </div> -->
           <table style="margin-top:-8px;text-align: center;font-family:'Arial',Times,serif;font-size:14px;font-weight:bold;width: 100%;">
                    <tr>
                      <td>
                        <input style="cursor:pointer" type="radio" id="tres" value="si" @click="validaFIEL('si')" v-model="tieneFIEL">
                        </td>
                        <td>
                            <input style="cursor:pointer" type="radio" id="cuatro" value="no" @click="validaFIEL('no')" v-model="tieneFIEL">
                        </td>
                    </tr>
                      <tr>
                      <td>
                        <label for="tres" style="margin-top:-15px;position: absolute;margin-left: -4px;">Si</label>
                        </td><td>
                            <label for="cuatro" style="margin-top:-15px;position: absolute;margin-left: -4px;">No</label>
                        </td>
                    </tr>
                  </table>     
          </v-card-title>
          </v-card>
        </v-col>
      </v-row>


    </v-container>
  
  </v-card>
</template>

<script>
import { VueMaskDirective   } from 'v-mask'

  export default {
    directives: {
      'mask': VueMaskDirective
    },
    components: {
     
    },
    data: () => ({
      tieneRFC:null,
      tieneFIEL:null,
      rfc:'',
      rulesRFC: [
        value => !!value || 'Requerido.',
        value => (value && value.length >= 12) || 'El RFC debe ser de 12 o 13 dígitos',
      ],
      fondoRFC:"white",
      fiscalesIcono:'https://utaxon.com/img/DATOS-FISCALES.png',
      colorTitulo1:'tituloAzul',
      dialogPropuesta:true,
      e6: '1',
      dialog: false,
      garantia:'https://previews.123rf.com/images/nerthuz/nerthuz1304/nerthuz130400003/18840808-satisfaction-guaranteed-label-with-gold-badge-sign.jpg',
      items: [
        {
          color: '#FB8C00',
          src: 'https://www.youtravel.com.au/wp-content/uploads/2017/05/airbnb.jpg',
          title: 'Apreciable, José Manuel!',
          artist: 'Agradecemos la oportunidad de presentar nuestra propuesta de servicios como Despacho Contable El paquete de servicio es de pago mensual y sin plazos forzosos.',
        }
      ],
      itemsPropuesta: [
        {
          color: '#fff',
          src: 'https://previews.123rf.com/images/nerthuz/nerthuz1304/nerthuz130400003/18840808-satisfaction-guaranteed-label-with-gold-badge-sign.jpg',
          title: 'Apreciable, José Manuel!',
          artist: 'Agradecemos la oportunidad de presentar nuestra propuesta de servicios como Despacho Contable El paquete de servicio es de pago mensual y sin plazos forzosos.',
        }
      ],
       propuestaItems: [
        { header: '.' },
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg', title: 'Emisión de facturas', subtitle: "<ul style='word-break:break-word;font-size:10px'><li >EMISIÓN DE 50 FACTURAS A TUS CLIENTES</li><li>EMISION DE FACTURAS AL PUBLICO EN GRAL</li></ul>" },
        { divider: true, inset: true },
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg', title: 'Declaraciones', subtitle: "<ul style='word-break:break-word;font-size:10px'><li>CALCULO DE IMPUESTOS PARA DECLARACIONES<br></li>PROVISIONALES</li><li>PRESENTACION DE DECLARACIONES<br></li>INFORMATIVAS</li><li>PRESENTACION DE DECLARACION ANUAL</li></ul>" },
        { divider: true, inset: true },
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg', title: 'Asesorias y seguimiento', subtitle: "<lu style='word-break:break-word;font-size:10px'><li>ASESORIAS FISCALES</li><li>TIPS PARA MEJOR DEDUCCIONES DE IMPUESTOS</li><li>ASESORIAS PERSONALIZADAS (TELEFONO, WHATSAPP, SKYPE, CORREO)</li><li>PLATFORMA WEB (CALCULOS DE IMPUESTOS),</li><li>SEGUIMIENTO DECLARACIONES</li></lu>" },
      ],
    }),
     mounted() {
      this.$nextTick(function() {
        window.addEventListener('resize', this.getWindowWidth);
        window.addEventListener('resize', this.getWindowHeight);
        //Init
        //this.getWindowWidth()
        this.getWindowHeight() 
      })

    },
    computed: {
      
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.getWindowWidth);
      window.removeEventListener('resize', this.getWindowHeight);
    },
   methods:{
     validaForma1(valor){
       //console.log(valor);
        if(valor=="no"){
          this.rfc="";
          this.tieneFIEL=null;
          this.$store.state.loading=false;
          this.$store.state.cliente.tieneRFC=false;
        }else{
           this.$store.state.loading=true; 
           this.$store.state.cliente.tieneRFC=true;
           
        }
     },

     validaFIEL(valor){
     
       if(valor=='si'){
         this.$store.state.cliente.tieneFIEL=true;
       }else{
         this.$store.state.cliente.tieneFIEL=false;
       }
           this.$store.state.loading=false; 
        
     },
     getWindowHeight(event) {
          
       if(document.documentElement.clientHeight <500){
          document.getElementById("fiscales").style.height= (document.documentElement.clientHeight+100)+'px';
        }else{
          document.getElementById("fiscales").style.height= (document.documentElement.clientHeight-180)+'px';
        }

          // document.getElementById("fiscales").style.height=(document.documentElement.clientHeight-180)+"px";
        
        },
     setRFC(event){
            console.log(event.key);
            var regex = new RegExp("^[a-zA-Z0-9]+$");
           
            if(event.key == "Unidentified"){
              //mobile
              var key=this.rfc[this.rfc.length-1];

                 if (!regex.test(key)) {
                   this.rfc=this.rfc.replace(key,'').replace(key,'').replace(key,'').replace(key,'');
                   event.preventDefault();
                   return false;
                 }

                this.rfc=this.rfc.toUpperCase();
                this.$store.state.cliente.rfc= this.rfc;
                if(this.rfc.length>=14){
                  this.fondoRFC="#f0f8ff";
                }

            }else{//pc
              var key = event.key;//String.fromCharCode(!event.key ? event.which : event.charCode);
                if (!regex.test(key)) {
                  this.rfc=this.rfc.replace(event.key,'')
                  event.preventDefault();
                  return false;
                }

                this.rfc=this.rfc.toUpperCase();
                this.$store.state.cliente.rfc= this.rfc;
                if(this.rfc.length>=14){
                  this.fondoRFC="#f0f8ff";
                }

            }
        },
   }
  }
</script>
<style scoped>

input[type='radio']:after {
        width: 22px;
        height: 22px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: white;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 9px solid #d1d3d1;
        z-index: 1;
    }

    input[type='radio']:checked:after {
        width: 22px;
        height: 22px;
        border-radius: 15px;
        top: -2px;
        left: -1px;
        position: relative;
        background-color: white;
        content: '';
        display: inline-block;
        visibility: visible;
        border: 9px solid #013872;
        z-index: 1;
    }
.tituloGris{
  color:#c7c3c3;
}

.tituloAzul{
  color:#013872;
}
</style>