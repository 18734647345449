<template>
     <v-card id="cPropiedades" flat class="mx-auto" style="margin-top:80px" >
      <v-container>
        <v-row dense>
          <v-col cols="12">
            <v-card elevation="0" style="height:80px" color="white">
              <v-card-title class="title font-weight-regular justify-space-between;">
              <v-img style="height:40px!important;width:50px!important;transition: width 1s;margin-top:-25px" contain :src="propiedadesIcono" aspect-ratio="1"></v-img>  
              <span :class="colorTitulo1" style="font-family:'Arial',Times,serif;font-size:20px;font-weight:bold;
                                                width: 100%;text-align: center;">Paquete de servicios</span>
            </v-card-title>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card elevation="1" style="height:130px" color="white">
              <v-card-title class="title font-weight-regular justify-space-between;">
              <span  style="font-family:'Arial',Times,serif;font-size:18px;font-weight:bold;margin-top:-20px;
                                                width: 100%;text-align: center;word-break:break-word;">Seleccione su paquete</span>
              <div  style="margin-top:10px;text-align: center;width:100%">
              <!-- combo -->
              <v-select
                  :items="propiedadesLista"
                  label="Paquete"
                  solo
                  menu-props="auto"
                  v-model="$store.state.cliente.propiedades"
                  @change="propiedadSeleccionada"
                  style="font-size:14px"
                ></v-select>
            </div>
            </v-card-title>
            </v-card>
          </v-col>

        

        


      
        </v-row>
      </v-container>
      </v-card>
</template>

<script>
import { VueMaskDirective   } from 'v-mask'

  export default {
    directives: {
      'mask': VueMaskDirective
    },
    components: {
     
    },
    data: () => ({
      propiedadesx:0,
      //propiedadesLista: ['01', '02', '03', '04','05','06','07','08','09','10','11', '12', '13', '14','15','16','17','18','19','20','21', '22', '23', '24','25','26','27','28','29','30','31', '32', '33', '34','35','36','37','38','39','40','41', '42', '43', '44','45','46','47','48','49','50','51', '52', '53', '54','55','56','57','58','59','60','61', '62', '63', '64','65','66','67','68','69','70','71', '72', '73', '74','75','76','77','78','79','80','81', '82', '83', '84','85','86','87','88','89','90','91', '92', '93', '94','95','96','97','98','99'],
      propiedadesLista:['PAQUETE BÁSICO','PAQUETE AVANZADO','PAQUETE PREMIUM'],
      tieneRFC:null,
      tieneFIEL:null,
      rfc:'',
      rulesRFC: [
        value => !!value || 'Requerido.',
        value => (value && value.length >= 15) || 'El RFC debe ser de 12 o 13 dígitos',
      ],
      fondoRFC:"white",
      propiedadesIcono:'https://utaxon.com/img/PROPIEDADES.png',
      colorTitulo1:'tituloAzul',
      dialogPropuesta:true,
      e6: '1',
      dialog: false,
      garantia:'https://previews.123rf.com/images/nerthuz/nerthuz1304/nerthuz130400003/18840808-satisfaction-guaranteed-label-with-gold-badge-sign.jpg',
      items: [
        {
          color: '#FB8C00',
          src: 'https://www.youtravel.com.au/wp-content/uploads/2017/05/airbnb.jpg',
          title: 'Apreciable, José Manuel!',
          artist: 'Agradecemos la oportunidad de presentar nuestra propuesta de servicios como Despacho Contable El paquete de servicio es de pago mensual y sin plazos forzosos.',
        }
      ],
      itemsPropuesta: [
        {
          color: '#fff',
          src: 'https://previews.123rf.com/images/nerthuz/nerthuz1304/nerthuz130400003/18840808-satisfaction-guaranteed-label-with-gold-badge-sign.jpg',
          title: 'Apreciable, José Manuel!',
          artist: 'Agradecemos la oportunidad de presentar nuestra propuesta de servicios como Despacho Contable El paquete de servicio es de pago mensual y sin plazos forzosos.',
        }
      ],
       propuestaItems: [
        { header: '.' },
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg', title: 'Emisión de facturas', subtitle: "<ul style='word-break:break-word;font-size:10px'><li >EMISIÓN DE 50 FACTURAS A TUS CLIENTES</li><li>EMISION DE FACTURAS AL PUBLICO EN GRAL</li></ul>" },
        { divider: true, inset: true },
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/2.jpg', title: 'Declaraciones', subtitle: "<ul style='word-break:break-word;font-size:10px'><li>CALCULO DE IMPUESTOS PARA DECLARACIONES<br></li>PROVISIONALES</li><li>PRESENTACION DE DECLARACIONES<br></li>INFORMATIVAS</li><li>PRESENTACION DE DECLARACION ANUAL</li></ul>" },
        { divider: true, inset: true },
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/3.jpg', title: 'Asesorias y seguimiento', subtitle: "<lu style='word-break:break-word;font-size:10px'><li>ASESORIAS FISCALES</li><li>TIPS PARA MEJOR DEDUCCIONES DE IMPUESTOS</li><li>ASESORIAS PERSONALIZADAS (TELEFONO, WHATSAPP, SKYPE, CORREO)</li><li>PLATFORMA WEB (CALCULOS DE IMPUESTOS),</li><li>SEGUIMIENTO DECLARACIONES</li></lu>" },
      ],
    }),
     mounted() {
     //this.$store.state.windowHeight;

      this.$nextTick(function() {
        window.addEventListener('resize', this.getWindowWidth);
        window.addEventListener('resize', this.getWindowHeight);
        //Init
        //this.getWindowWidth()
        this.getWindowHeight() 
      })

    },
    computed: {
      
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.getWindowWidth);
      window.removeEventListener('resize', this.getWindowHeight);
    },
   methods:{
     propiedadSeleccionada(){

        this.$store.state.loading=false;
          //this.$store.state.cliente.propiedades= this.propiedadesx;

      },
     getWindowHeight(event) {
          
        
         if(document.documentElement.clientHeight <500){
          document.getElementById("cPropiedades").style.height= (document.documentElement.clientHeight+100)+'px';
        }else{
          document.getElementById("cPropiedades").style.height= (document.documentElement.clientHeight-180)+'px';
        }


          
           //document.getElementById("cPropiedades").style.height=(document.documentElement.clientHeight-220)+"px";
        
        },
     setRFC(){
            this.rfc=this.rfc.toUpperCase();
            this.$store.state.cliente.rfc= this.rfc;
            if(this.rfc.length>=15){
              this.fondoRFC="#f0f8ff";
             // this.validaforma1Completa();
            }
            //this.validaforma1Completa();
        },
   }
  }
</script>
<style scoped>
.tituloGris{
  color:#c7c3c3;
}

.tituloAzul{
  color:#013872;
}
</style>