<template>
     <v-card class="mx-auto">
    <v-container>
      <v-row dense>
        <v-col
          v-for="(item, i) in itemsPropuesta"
          :key="i"
          cols="12">
          <v-card :color="item.color" dark>
            <div class="d-flex flex-no-wrap justify-space-between" style="height:95px">
               <v-col cols=8>
               <div style="">
                <span class="headline" 
                style="font-weight:bold;color:black;display:inline-block;font-size:16px!important">Apreciable, cliente</span>
                <v-card-subtitle style="word-break:break-word;line-height: 0.9rem;margin-left:-15px;width:116%;font-size: 12px;
                margin-top: -22px;" 
                class="black--text" v-text="item.artist"></v-card-subtitle>
              </div>
              </v-col>
              <v-col cols="4">
                <v-avatar style="top:-17px;right:-10px;position: absolute;" class="ma-3" size="100" tile>
                <v-img :src="item.src" ></v-img>
              </v-avatar>
              </v-col>
            </div>

            <v-card-text style="background-color: #a9a9a93b;padding:5px">
              <v-card style="background-color: white;" class="mx-auto" max-width="800" height="300" color="white" tile>
              <v-list two-line style="background-color:white" dense>
                 <template>
                  <v-subheader style="color:grey;"></v-subheader>
                  <v-list-item style="color:grey;margin-top:-48px;">
                    <v-list-item-content>
                      <v-list-item-title style="color:black;word-break:break-word;line-height: 0.9rem;margin-top:0px" >CONFIGURACIÓN PERSONALIZADA</v-list-item-title>
                      <v-list-item-subtitle style="color:grey;margin-left: 0px;font-size:11px" >PROPIEDADES EN RENTA: (<span style="color: #ff0000">{{$store.state.cliente.propiedades}}</span>)</v-list-item-subtitle>
                      <!-- <v-list-item-subtitle style="color:black;margin-left: 0px;font-size:11px" >PROMEDIO DE FACTURACION A GENERAR MENSUALMENTE A <br>SUS CLIENTES: (<span style="color: #ff0000">0</span> CFDI)</v-list-item-subtitle> -->
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-list>

              <v-simple-table dense  light >
                <template v-slot:default>
                  <tbody>
                     <tr >
                      <td style="font-size:13px;font-weight: bold;">SUBTOTAL</td>
                      <td style="font-size:15px;font-weight: bold;color:green" class="text-right">${{$store.getters.getDesde}}</td>
                    </tr>
                     <tr >
                      <td style="font-size:13px;font-weight: bold;">IVA</td>
                      <td style="font-size:15px;font-weight: bold;color:green" class="text-right">${{$store.getters.getIVADesde}}</td>
                    </tr>
                    <tr >
                      <td style="font-size:13px;font-weight: bold;">TOTAL</td>
                      <td style="font-size:15px;font-weight: bold;color:green" class="text-right">${{$store.getters.getCalculoTotal}}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <br>
              <span style="word-break:break-word;line-height: 0.9rem;font-size:13px;color:black;margin-left:15px;display:block" >Puede comunicarse a nuestro call center al número 55 6366 5042 para dudas y contratación. Además un asesor lo contactará en las siguientes horas.</span>
              <span style="word-break:break-word;line-height: 0.9rem;font-size:13px;color:black;margin-left:15px;display:block;margin-top:10px" >Gracias por su interes.</span>

              </v-card>
            </v-card-text>

          </v-card>
        </v-col>
      </v-row>


    </v-container>
  
  </v-card>
</template>

<script>

  export default {
    components: {
     
    },
    data: () => ({
      dialogPropuesta:true,
      e6: '1',
      dialog: false,
      garantia:'https://previews.123rf.com/images/nerthuz/nerthuz1304/nerthuz130400003/18840808-satisfaction-guaranteed-label-with-gold-badge-sign.jpg',
      items: [
        {
          color: '#FB8C00',
          src: 'https://www.youtravel.com.au/wp-content/uploads/2017/05/airbnb.jpg',
          title: 'Apreciable, José Manuel!',
          artist: 'Agradecemos la oportunidad de presentar nuestra propuesta de servicios como Despacho Contable El paquete de servicio es de pago mensual y sin plazos forzosos.',
        }
      ],
      itemsPropuesta: [
        {
          color: '#fff',
          src: 'https://previews.123rf.com/images/nerthuz/nerthuz1304/nerthuz130400003/18840808-satisfaction-guaranteed-label-with-gold-badge-sign.jpg',
          title: 'Apreciable, José Manuel! (Continuación)',
          artist: 'Le enviaremos esta información a su correo para que la revise:',
        }
      ],
       propuestaItems: [
        { header: '.' },
        { avatar: 'https://cdn.vuetifyjs.com/images/lists/1.jpg', title: 'CONFIGURACION PERSONALIZADA', subtitle: "<ul><li>PROPIEDADES EN RENTA: (1 Propiedad)</li><li>PROMEDIO DE FACTURACION A GENERAR MENSUALMENTE A <br>SUS CLIENTES: (5 CFDI)</li></ul>" },
        { divider: true, inset: true },
      ],
      montos: [
          {
            concepto: 'TOTAL',
            valor: 329.96,
          }
        ],
    }),

  }
</script>
